import { IDropdownOption } from '@fluentui/react'

export enum ModuleType {
  Custom = 'custom',
  BackupCopies = 'backup_copies',
  ClusterManagement = 'cluster_management',
  Firewall = 'firewall',
  FlexxAnalyzer = 'flexx_analyzer',
  FlexxAutomation = 'flexx_automation',
  FlexxMonitoring = 'flexx_monitoring',
  FlexxWorkspaces = 'flexx_workspaces',
  PerimeterSecurity = 'perimeter_security',
  VirtualizationPlatform = 'virtualization_platform',
}

export const ModuleTypes: IDropdownOption[] = [
  { key: ModuleType.Custom, text: 'Custom' },
  { key: ModuleType.BackupCopies, text: 'Backup copies' },
  { key: ModuleType.ClusterManagement, text: 'Cluster management' },
  { key: ModuleType.Firewall, text: 'Firewall' },
  { key: ModuleType.FlexxAnalyzer, text: 'Analyzer' },
  { key: ModuleType.FlexxAutomation, text: 'Automate' },
  { key: ModuleType.FlexxMonitoring, text: 'Monitor' },
  { key: ModuleType.FlexxWorkspaces, text: 'Workspaces' },
  { key: ModuleType.PerimeterSecurity, text: 'Perimeter security' },
  { key: ModuleType.VirtualizationPlatform, text: 'Virtualization platform' },
]
