import { LateralPanel, LateralPanelProps } from "../../lateral-panel/LateralPanel.component";
import ErrorStatus from "../../status/error-status";
import SuccessStatus from "../../status/success-status";
import { Status, useFormSidebarContext } from "./state/FormSidebarState";

export interface FormSidebarProps {
  lateralPanelProps: Partial<LateralPanelProps>,
  children: any;
}

const SidebarBody = ({ status, statusProps, children }: { status: Status | undefined, statusProps: any, children: any }) => {
  switch (status) {
    case 'SUCCESS':
      return <SuccessStatus {...statusProps} />
    case 'ERROR':
      return <ErrorStatus {...statusProps} />
    default:
      return <>{children}</>
  }
}

function FormSidebar() {
  const { status: formSidebarStatus, closeSidebar, isSidebarOpen, content, formSidebarProps }
    = useFormSidebarContext()

  return (
    <>
      <LateralPanel isOpen={isSidebarOpen} handleOnClose={closeSidebar} {...formSidebarProps}>
        <SidebarBody status={formSidebarStatus?.status} statusProps={formSidebarStatus?.statusProps}>
          {content}
        </SidebarBody>
      </LateralPanel>
    </>
  )
}

export default FormSidebar;