import { BusinessType } from 'app/models/business/BusinessType';
import EndCustomer from '../assets/img/end-customer.svg';
import Partner from '../assets/img/partner.svg';
import Wholesaler from '../assets/img/wholesaler.svg';
import NoIcon from '../assets/img/layers.svg';

export const getIcon = (type: BusinessType) => {
  switch (type) {
    case BusinessType.END_CUSTOMER:
      return EndCustomer
    case BusinessType.PARTNER:
        return Partner
    case BusinessType.WHOLESALER:
        return Wholesaler
    default: 
      return NoIcon;
  }
}


export const getOrganizationTypeIcon = (type: BusinessType, width: number = 25, height: number = 25) => {
  return <img src={getIcon(type)} alt={type} width={width} height={height} />
}
