import { createContext, ReactNode } from 'react'
import DoptProvider from './DoptProvider'
import { GlobalContextAuth, globalContextAuth } from './globalContextAuth'
import {
  GlobalContextOrganizationSelection,
  globalContextOrganizationSelection,
} from './globalContextOrganizationSelection'
import { useNavigate } from 'react-router-dom'

export interface GlobalContextType {
  auth: GlobalContextAuth
  organizationSelection: GlobalContextOrganizationSelection
}

export const GlobalContext = createContext<GlobalContextType>(null!)

export const GlobalProvider = ({ children }: { children: ReactNode }) => {
  const navigate = useNavigate()
  const auth = globalContextAuth()
  const organizationSelection = globalContextOrganizationSelection(navigate)
  return (
    <GlobalContext.Provider value={{ auth, organizationSelection }}>
      <DoptProvider>{children}</DoptProvider>
    </GlobalContext.Provider>
  )
}
