import { Icon, ISearchBox, ISelectableOption } from '@fluentui/react'
import { Business } from '../../../query-client/types'
import { theme } from '../../../config/theme'
import * as React from 'react'
import { TFunction } from 'react-i18next'
import { Badge } from 'app/components/Badge/Badge'
import { getOrganizationTypeIcon } from 'app/services/organization-icon-service'
import { BusinessType } from 'app/models/business/BusinessType'

export const renderOrganizationSelectorDropdownItem = (
  translator: TFunction,
  item?: ISelectableOption,
  userOrganization?: Business,
  isTrial: boolean = false,
  darkMode: boolean = false,
  isMobileHeader?: boolean,
  searchRef?: React.MutableRefObject<ISearchBox | null>
): JSX.Element | null => {
  const getSubtitle = (type: BusinessType): string => {
    switch (type) {
      case BusinessType.END_CUSTOMER:
        return translator('general:ORGANIZATION_SELECTION.CLIENT_ORGANIZATION')
      case BusinessType.PARTNER:
        return translator('general:ORGANIZATION_SELECTION.PARTNER_ORGANIZATION')
      case BusinessType.WHOLESALER:
        return translator('general:ORGANIZATION_SELECTION.WHOLESALER_ORGANIZATION')
      default:
        return ''
    }
  }

  if (isMobileHeader) {
    return (
      <Icon
        iconName={'AccountManagement'}
        style={{
          color: theme.palette?.neutralLighter,
          fontSize: '15px',
        }}
      />
    )
  }

  if (!item) {
    return null
  }

  return (
    <div
      onMouseEnter={() => {
        setTimeout(() => searchRef?.current?.focus(), 500);
      }}
      onMouseLeave={() => {
        setTimeout(() => searchRef?.current?.focus(), 500);
      }}
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: '3px 8px',
        width: '100%',
      }}
    >
      <div className="organization_type_img">{item?.data?.type && getOrganizationTypeIcon(item.data.type)}</div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          width: '100%',
          overflow: 'hidden',
        }}
      >
        <div
          style={{
            color: darkMode ? theme.palette?.neutralLighter : '',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            paddingRight: '8px',
          }}
        >
          {item.text}
        </div>

        {userOrganization && item?.key && (
          <div
            style={{
              color: darkMode ? theme.palette?.neutralTertiaryAlt : theme.semanticColors?.bodySubtext,
            }}
          >
            {isTrial && <Badge>{translator('organizations:TRIAL')}</Badge>}

            {userOrganization._id === item.key
              ? translator('general:ORGANIZATION_SELECTION.MY_ORGANIZATION')
              : getSubtitle(item?.data?.type)}
          </div>
        )}
      </div>
    </div>
  )
}
