import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { AnalyzerPermission, Role, WorkspacePermission } from 'app/query-client/types'
import { ClientError, gql } from 'graphql-request'
import graphClient from '../../query-client/graphClient'

interface MySelfOnResponse {
  portal: Role
  workspaces: WorkspacePermission
  analyzer: AnalyzerPermission
}

const query = gql`
  query getMyselfOn($organizationId: ID!) {
    getMyPermissionsByOrganization(organizationId: $organizationId) {
      portal
      workspaces
      analyzer
    }
  }
`

function getMyselfOn(organizationId: string): Promise<MySelfOnResponse> {
  return graphClient.request(query, { organizationId }).then((r) => r.getMyPermissionsByOrganization)
}

export const useMySelfOn = (organizationId?: string): UseQueryResult<MySelfOnResponse, ClientError> => {
  return useQuery(['getMyselfOn', organizationId], () => getMyselfOn(organizationId ?? ''), {
    refetchOnWindowFocus: false,
    enabled: !!organizationId,
  })
}
