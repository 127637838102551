import { WorkspacePermissionName, WorkspacePermissions } from './UserWorkspacePermission.enum'

export const AnalyzerPermissionNames = [
    'ACCESS',
    'NO_ACCESS',
    'ADMIN'
] as const

export type AnalyzerPermissionName = (typeof AnalyzerPermissionNames)[number]


export const AnalyzerPermissions = {
    ACCESS: 'ACCESS',
    NO_ACCESS: 'NO_ACCESS',
    ADMIN: 'ADMIN'
}


export const analyzerPermissionLocalizationKeyDictionary = {
    ACCESS: 'USERS.ANALYZER_PERMISSION.ACCESS',
    NO_ACCESS: 'USERS.ANALYZER_PERMISSION.NO_ACCESS',
    ADMIN: 'USERS.ANALYZER_PERMISSION.ADMIN'
}



export const hasEnoughAnalyzerPermissions = (
  authAnalyzer: AnalyzerPermissionName,
  requestAnalyzer: AnalyzerPermissionName
): boolean => {
  const orderedAnalyzer = [
    AnalyzerPermissions.ADMIN,
    AnalyzerPermissions.ACCESS,
    AnalyzerPermissions.NO_ACCESS
  ]
  const authIndex = orderedAnalyzer.indexOf(authAnalyzer)
  const requestIndex = orderedAnalyzer.indexOf(requestAnalyzer)
  
  return requestIndex >= authIndex;
}