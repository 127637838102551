import Status404 from "app/components/status/404-status"
import { useTranslation } from "react-i18next"

const NotFound = () => {
  const { t } = useTranslation('general')

  return (
    <div style={{ height: '100vh' }}>
    <Status404
        message={ t('404_NOT_FOUND') }
        explanation={ t('404_EXPLANATION') }
      />
    </div>
  )
}

export default NotFound