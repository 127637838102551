import { IDropdownStyles } from '@fluentui/react'
import { defaultDropdownStyles } from '../../../forms/Dropdown'
import { theme } from '../../../../config/theme'

export const getSidebarOrganizationSelectorDropdownStyles = (isMobile: boolean = false): Partial<IDropdownStyles> => {
  const base: Partial<IDropdownStyles> = {
    ...defaultDropdownStyles,
    root: Object.assign({}, defaultDropdownStyles.root, {
      width: '300px',
    }),
    title: Object.assign({}, defaultDropdownStyles.title, {
      height: 'fit-content',
      lineHeight: '20px',
      paddingLeft: 0,
      paddingTop: '1px',
      paddingBottom: '1px',
      backgroundColor: theme.palette?.neutralPrimary,
      borderColor: theme.palette?.neutralPrimary,
      borderRadius: '10px',
    }),
    caretDownWrapper: Object.assign({}, defaultDropdownStyles.caretDownWrapper, {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    }),
    caretDown: Object.assign({}, defaultDropdownStyles.caretDown, {
      color: theme.palette?.themePrimary,
    }),
    dropdown: {
      ':focus::after': {
        border: 'none',
      },
    },
    dropdownItem: Object.assign({}, defaultDropdownStyles.dropdownItem, {
      height: 'fit-content',
    }),
    dropdownItemSelected: Object.assign({}, defaultDropdownStyles.dropdownItemSelected, {
      height: 'inherit',
    }),
    subComponentStyles: {
      multiSelectItem: {
        checkbox: { display: 'none' },
      },
      label: {
        ...defaultDropdownStyles.subComponentStyles?.label,
      },
      panel: { ...defaultDropdownStyles.subComponentStyles?.panel },
    },
  }

  const desktop: Partial<IDropdownStyles> = {
    ...base,
    callout: Object.assign({}, base.callout, {
      minWidth: '200px',
      height: '600px',
      maxHeight: '600px',
      backgroundColor: 'transparent',
      boxShadow: 'none',

      ':first-child > :first-child': {
        backgroundColor: theme.palette?.neutralPrimary,
        marginTop: '-7px',
        borderRadius: '0 0 10px 10px',
        scrollbarColor: `${theme.palette?.neutralPrimaryAlt} ${theme.palette?.black}`,
        scrollbarWidth: 'thin',
      },
    }),
    dropdownItem: Object.assign({}, base.dropdownItem, {
      backgroundColor: theme.palette?.neutralPrimary,
      ':hover': {
        backgroundColor: theme.palette?.neutralPrimaryAlt,
      },
      selectors: {
        '&[tabindex="0"]': {
          backgroundColor: theme.palette?.neutralPrimaryAlt,
        },
      },
    }),
    dropdownItems: {
      background: theme.palette?.neutralPrimaryAlt,
    },
    dropdownItemSelected: Object.assign({}, base.dropdownItemSelected, {
      backgroundColor: theme.palette?.neutralPrimaryAlt + ' !important',
      ':hover': {
        backgroundColor: theme.palette?.neutralPrimaryAlt,
      },
    }),
  }

  if (isMobile) {
    return {
      ...base,
      root: {
        backgroundColor: theme.palette?.neutralPrimary,
        borderRadius: '50%',
      },

      title: {
        backgroundColor: 'transparent',
        borderColor: 'transparent',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '15px',
        width: '32px',
        height: '32px',
      },
    }
  }
  return desktop
}
