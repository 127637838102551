import { IButtonProps } from "@fluentui/react";
import SuccessCheck from '../../assets/img/success_icon.svg';
import StatusLayout from "./status-layout/status-layout";

export interface SuccessStatusProps {
  message: string;
  buttonProps?: IButtonProps;
}

const SuccessStatus = ({ message, buttonProps }: SuccessStatusProps) => {
  return (
    <StatusLayout 
      img={SuccessCheck}
      message={message}
      buttonProps={buttonProps}
    ></StatusLayout>
  )
}

export default SuccessStatus