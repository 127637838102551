import {
  DirectionalHint,
  Icon,
  TooltipDelay,
  TooltipHost,
} from '@fluentui/react'
import * as React from 'react'
import { TFunction } from 'react-i18next'

interface Props {
  translator: TFunction
}

export const SidebarOrganizationSelectorLabel = (props: Props) => {
  return (
    <div className="sidebar_organization_selector__header">
      <span className="sidebar_organization_selector__label">
        {props.translator('general:ORGANIZATION_SELECTION.LABEL')}
      </span>
      <TooltipHost
        delay={TooltipDelay.zero}
        directionalHint={DirectionalHint.topCenter}
        content={props.translator('general:ORGANIZATION_SELECTION.TOOLTIP')}
      >
        <Icon
          className="cursor-default"
          iconName="Info"
          style={{
            display: 'inline-block',
            marginTop: 5,
          }}
        />
      </TooltipHost>
    </div>
  )
}
