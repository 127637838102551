import type { RouteObject } from 'react-router-dom'

export const ODIN_REPORTS_ROOT_PATH = 'odin-reports'

export const odinReportsRoutes: RouteObject[] = [
  {
    path: ODIN_REPORTS_ROOT_PATH,
    children: [
      {
        index: true,
        lazy: () => import(/* webpackPrefetch: true */ 'app/pages/odin-reports/OdinReports.page'),
      },
      {
        path: ':id',
        lazy: () =>
          import(/* webpackPrefetch: true */ 'app/pages/odin-reports/list/executions/detail/OdinReportExecutionDetail'),
      },
    ],
  },
]
