import { ReactComponent as IntelVProIcon } from 'app/assets/img/workspace-details/IntelvPro.svg'
import { ReactComponent as ActiveNotifications_Purple } from 'app/assets/img/workspace-details/ActiveNotifications_Purple.svg'
import { ReactComponent as ActiveNotificationsAckAccepted } from 'app/assets/img/workspace-details/ActiveNotificationsAckAccepted.svg'
import { ReactComponent as ActiveNotificationsAckPending } from 'app/assets/img/workspace-details/ActiveNotificationsAckPending.svg'
import { ReactComponent as AmazonWorkspaces } from 'app/assets/img/workspace-details/AmazonWorkspaces.svg'
import { ReactComponent as Android } from 'app/assets/img/workspace-details/android.svg'
import { ReactComponent as AVD } from 'app/assets/img/workspace-details/AVD.svg'
import { ReactComponent as AWS } from 'app/assets/img/workspace-details/AWS.svg'
import { ReactComponent as Azure } from 'app/assets/img/workspace-details/Azure.svg'
import { ReactComponent as Chrome } from 'app/assets/img/workspace-details/chrome.svg'
import { ReactComponent as CitrixOnPrem } from 'app/assets/img/workspace-details/CitrixOnPrem.svg'
import { ReactComponent as CitrixX } from 'app/assets/img/workspace-details/CitrixX.svg'
import { ReactComponent as Cloud } from 'app/assets/img/workspace-details/Cloud.svg'
import { ReactComponent as Compliant } from 'app/assets/img/workspace-details/Compliant.svg'
import { ReactComponent as CrowdStrike } from 'app/assets/img/workspace-details/CrowdStrike.svg'
import { ReactComponent as CrowdStrikeWarning } from 'app/assets/img/workspace-details/CrowdStrikeWarning.svg'
import { ReactComponent as Ethernet } from 'app/assets/img/workspace-details/Ethernet.svg'
import { ReactComponent as GoogleCloud } from 'app/assets/img/workspace-details/GoogleCloud.svg'
import { ReactComponent as HyperV } from 'app/assets/img/workspace-details/HyperV.svg'
import { ReactComponent as Laptop } from 'app/assets/img/workspace-details/laptop.svg'
import { ReactComponent as Linux } from 'app/assets/img/workspace-details/linux.svg'
import { ReactComponent as Mac } from 'app/assets/img/workspace-details/mac.svg'
import { ReactComponent as Maintenance } from 'app/assets/img/workspace-details/Maintenance.svg'
import { ReactComponent as MobileNetwork } from 'app/assets/img/workspace-details/MobileNetwork.svg'
import { ReactComponent as MobileNetwork_20 } from 'app/assets/img/workspace-details/MobileNetwork_20.svg'
import { ReactComponent as MobileNetwork_40 } from 'app/assets/img/workspace-details/MobileNetwork_40.svg'
import { ReactComponent as MobileNetwork_60 } from 'app/assets/img/workspace-details/MobileNetwork_60.svg'
import { ReactComponent as MobileNetwork_80 } from 'app/assets/img/workspace-details/MobileNetwork_80.svg'
import { ReactComponent as MobileNetwork_100 } from 'app/assets/img/workspace-details/MobileNetwork_100.svg'
import { ReactComponent as NonCompliant } from 'app/assets/img/workspace-details/NonCompliant.svg'
import { ReactComponent as Other } from 'app/assets/img/workspace-details/Other.svg'
import { ReactComponent as ParallelsRAS } from 'app/assets/img/workspace-details/ParallelsRAS.svg'
import { ReactComponent as PowerstateOff } from 'app/assets/img/workspace-details/PowerstateOff.svg'
import { ReactComponent as PowerstateUnknown } from 'app/assets/img/workspace-details/PowerstateUnknown.svg'
import { ReactComponent as Unknown } from 'app/assets/img/workspace-details/Unknown.svg'
import { ReactComponent as Unregistered } from 'app/assets/img/workspace-details/Unregistered.svg'
import { ReactComponent as VMWareHorizon } from 'app/assets/img/workspace-details/VMWareHorizon.svg'
import { ReactComponent as vSphere } from 'app/assets/img/workspace-details/vSphere.svg'
import { ReactComponent as Wifi } from 'app/assets/img/workspace-details/Wifi.svg'
import { ReactComponent as Wifi_25 } from 'app/assets/img/workspace-details/Wifi_25.svg'
import { ReactComponent as Wifi_50 } from 'app/assets/img/workspace-details/Wifi_50.svg'
import { ReactComponent as Wifi_75 } from 'app/assets/img/workspace-details/Wifi_75.svg'
import { ReactComponent as Wifi_100 } from 'app/assets/img/workspace-details/Wifi_100.svg'
import { ReactComponent as windows } from 'app/assets/img/workspace-details/windows.svg'
import { ReactComponent as WorkspaceStatusOk } from 'app/assets/img/workspace-details/WorkspaceStatusOk.svg'

import React from 'react'

export const getListIconsWorkspace = () => {
  return {
    intelvpro: React.createElement(IntelVProIcon),
    activeNotificationsPurple: React.createElement(ActiveNotifications_Purple),
    activeNotificationsAckAccepted: React.createElement(ActiveNotificationsAckAccepted),
    activeNotificationsAckPending: React.createElement(ActiveNotificationsAckPending),
    amazonWorkspaces: React.createElement(AmazonWorkspaces),
    android: React.createElement(Android),
    avd: React.createElement(AVD),
    aws: React.createElement(AWS),
    azure: React.createElement(Azure),
    chrome: React.createElement(Chrome),
    citrixOnPrem: React.createElement(CitrixOnPrem),
    citrixX: React.createElement(CitrixX),
    cloud: React.createElement(Cloud),
    compliant: React.createElement(Compliant),
    crowdStrike: React.createElement(CrowdStrike),
    crowdStrikeWarning: React.createElement(CrowdStrikeWarning),
    ethernet: React.createElement(Ethernet),
    googleCloud: React.createElement(GoogleCloud),
    hyperV: React.createElement(HyperV),
    laptop: React.createElement(Laptop),
    linux: React.createElement(Linux),
    mac: React.createElement(Mac),
    maintenance: React.createElement(Maintenance),
    mobileNetwork: React.createElement(MobileNetwork),
    mobileNetwork_20: React.createElement(MobileNetwork_20),
    mobileNetwork_40: React.createElement(MobileNetwork_40),
    mobileNetwork_60: React.createElement(MobileNetwork_60),
    mobileNetwork_80: React.createElement(MobileNetwork_80),
    mobileNetwork_100: React.createElement(MobileNetwork_100),
    nonCompliant: React.createElement(NonCompliant),
    other: React.createElement(Other),
    parallelsRAS: React.createElement(ParallelsRAS),
    powerstateOff: React.createElement(PowerstateOff),
    powerstateUnknown: React.createElement(PowerstateUnknown),
    // rDSH: React.createElement(RDSH),
    'flexx-unknown': React.createElement(Unknown),
    unregistered: React.createElement(Unregistered),
    vMWareHorizon: React.createElement(VMWareHorizon),
    vSphere: React.createElement(vSphere),
    wifi: React.createElement(Wifi),
    wifi_25: React.createElement(Wifi_25),
    wifi_50: React.createElement(Wifi_50),
    wifi_75: React.createElement(Wifi_75),
    wifi_100: React.createElement(Wifi_100),
    windows: React.createElement(windows),
    // windows365: React.createElement(Windows365),
    workspaceStatusOk: React.createElement(WorkspaceStatusOk),
  }
}
