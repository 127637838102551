import { LoadingSpinner } from '@flexxibleit/flexxible-ui';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../globals/useAuth';
import { useAuth0 } from '@auth0/auth0-react';

export const RequireAuth = ({ children }: { children: JSX.Element }) => {
  const auth = useAuth();
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
  const location = useLocation();
  const isTokenExpired = () => {
    const tokenDecoded = auth.user?.token ? JSON.parse(atob(auth.user.token.split('.')[1])) : null;
    const tokenExpiration = tokenDecoded?.exp ? new Date(tokenDecoded.exp * 1000) : null;
    return tokenExpiration ? tokenExpiration < new Date() : false;
  };

  if (isLoading) {
    return <LoadingSpinner/>;
  }

  if (!isAuthenticated) {
    auth.logout();
    loginWithRedirect();
  }

  if (!auth.user) {
    return <Navigate to="/login" state={{ from: location }} replace />
  }

  if (isTokenExpired()) {
      auth.logout();
      loginWithRedirect();
  }

  return children
}
