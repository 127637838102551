import { Icon, Label } from '@fluentui/react'
import { Flow, useFlow, useFlowStatus } from '@dopt/react'
import { useTranslation } from 'react-i18next'
import { getTagFromState, refreshFlowIconStyles } from '../shared'
import { LoadingSpinner } from '@flexxibleit/flexxible-ui'
import { InitialSetupFlowPage } from './components/InitialSetupFlowPage'
import React from 'react'
import { useFeatureRender } from 'app/permissions/useFeatureRender'
import { FEATURE_NAMES } from 'app/permissions/FeatureName.enum'

interface UseInitialSetupFlowReturn {
  initialSetupFlow: Flow
  renderInitialSetupFlow: () => JSX.Element | undefined
  initialSetupFlowSettings: JSX.Element
}

export const useInitialSetupFlow = (): UseInitialSetupFlowReturn => {
  const { t } = useTranslation('user_journey')
  const { checkFeature } = useFeatureRender()
  const flowStatus = useFlowStatus('portal-initial-setup-wizard')
  const [initialSetupFlow, intentInitialSetupFlow] = useFlow('portal-initial-setup-wizard')

  const initialSetupFlowEnabled = initialSetupFlow.blocks.length > 0

  if (!initialSetupFlowEnabled || !checkFeature(FEATURE_NAMES.USER_JOURNEY_READ)) {
    return {
      initialSetupFlow,
      renderInitialSetupFlow: () => undefined,
      initialSetupFlowSettings: <></>,
    }
  }

  const initialSetupFlowSettings = (
    <>
      <Label className={'mt-2'}>{t('INITIAL_SETUP_FLOW.RESET_LABEL')}</Label>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          marginTop: '8px',
          gap: '8px',
        }}
      >
        {getTagFromState(initialSetupFlow.state, t)}
        <Icon
          iconName="Refresh"
          onClick={() => {
            intentInitialSetupFlow.reset()
          }}
          styles={refreshFlowIconStyles}
        />
      </div>
    </>
  )

  const renderInitialSetupFlow = () => {
    if (flowStatus.pending && !flowStatus.failed) {
      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            width: '100%',
          }}
        >
          <LoadingSpinner />
        </div>
      )
    }

    if (initialSetupFlow.state.started && !initialSetupFlow.state.finished && !initialSetupFlow.state.stopped) {
      return <InitialSetupFlowPage />
    }
  }

  return {
    initialSetupFlow,
    renderInitialSetupFlow,
    initialSetupFlowSettings,
  }
}
