import { Icon, INavLink, IRenderFunction, IRenderGroupHeaderProps, Nav } from '@fluentui/react'
import React, { useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { navLinkGroups } from './Sidebar.elements'
import { useFeatureRender } from '../../permissions/useFeatureRender'
import './Sidebar.component.scss'
import { useLocalStorageSearchParams } from 'app/hooks/utils/useLocalStorageSearchParams'
import { EnsWarningMessage } from './EnsWarningMessage'
import { RELEASE_ENVIRONMENT } from 'app/config/Consts'
import { Environment } from 'app/config/environment.enum'
import { FeatureRender } from 'app/permissions/FeatureRender'
import { ViewAsEndUserSwitcher } from '../ViewAsEndUserSwitcher/ViewAsEndUserSwitcher'
import { FEATURE_NAMES } from 'app/permissions/FeatureName.enum'
import { useEnsMessage } from './useEnsMessage'
import { useFeaturit } from '../../globals/context/FeaturitProvider'
import { FeaturitFlag } from '../../permissions/authorizer/FeatureFlags'

interface Props {
  isOpen: boolean
}

export const Sidebar = ({ isOpen }: Props) => {
  const { checkFeature } = useFeatureRender()
  const navigate = useNavigate()
  const location = useLocation()
  const { getPersistedSearchParams } = useLocalStorageSearchParams(false)

  const { ensMessageState, setEndMessageState } = useEnsMessage()
  const isStaging = useMemo(() => RELEASE_ENVIRONMENT === Environment.STAGING, [])

  const featurit = useFeaturit()

  const _onLinkClick = (ev?: React.MouseEvent<HTMLElement>, item?: INavLink) => {
    if (item?.target) {
      return
    }
    ev?.preventDefault()
    ev?.stopPropagation()
    navigate(item!.url + getPersistedSearchParams(item!.url))
  }

  const customRenderGroupHeader: IRenderFunction<IRenderGroupHeaderProps> = (props, defaultRender) => {
    const hasAccessibleLinks = props?.links?.some((link) => {
      const isEnabledFeatureFlag = link.featureFlag ? featurit.isActive(link.featureFlag) : true
      return checkFeature(link.feature) && isEnabledFeatureFlag
    })

    if (!hasAccessibleLinks) {
      return null
    }

    if (!props?.groupData?.icon) {
      return defaultRender!(props)
    }

    return (
      <div
        className="content-header__custom-group-header"
        style={{ display: 'flex', alignItems: 'center', position: 'relative' }}
      >
        <Icon className="custom-icon" iconName={props.groupData.icon} />
        {defaultRender!(props)}
        {props.groupData.isPreview && (
          <div id="titleTagPage" className={`content-header__title-tag-navLink`}>
            PREVIEW
          </div>
        )}
      </div>
    )
  }

  const sidebarElements = navLinkGroups()
  const path = '/' + location.pathname.split('/')[1]
  const selectedGroup = sidebarElements.find((group) => {
    return group.links.find((link) => [path, location.pathname].includes(link.url))
  })
  if (selectedGroup?.collapseByDefault) {
    selectedGroup.collapseByDefault = false
  }

  const selected = selectedGroup?.links.find((link) => [path, location.pathname].includes(link.url)) ?? {
    key: undefined,
  }

  return (
    <div>
      <div className={`sidebar ${isOpen ? 'open' : ''}`}>
        <Nav
          className="side-navbar"
          selectedKey={selected.key ?? ''}
          onLinkClick={_onLinkClick}
          groups={sidebarElements}
          onRenderGroupHeader={customRenderGroupHeader}
          linkAs={(props) => {
            const featureFlag = props.link!.featureFlag
            if (featureFlag && !featurit.isActive(featureFlag)) {
              return null
            }

            const feature = props.link!.feature
            if (feature && !checkFeature(props.link!.feature)) {
              return null
            }
            return (
              <div
                className="content-header__custom-group-header"
                style={{ display: 'flex', alignItems: 'center', position: 'relative' }}
              >
                <props.defaultRender {...props} />
                {props.link?.isPreview && <div className={`content-header__title-tag-navLink`}>PREVIEW</div>}
              </div>
            )
          }}
        />
        {featurit.isActive(FeaturitFlag.VIEW_AS_END_USER) && (
          <FeatureRender feature={FEATURE_NAMES.VIEW_AS_END_USER}>
            <div
              className="view-as-end-user__wrapper"
              style={{
                bottom: isStaging ? 'var(--banner-height)' : '0',
              }}
            >
              <ViewAsEndUserSwitcher />
            </div>
          </FeatureRender>
        )}
        <div
          style={{
            position: 'absolute',
            zIndex: 10,
            bottom: isStaging ? 'var(--banner-height)' : '0',
          }}
        >
          {ensMessageState == 'open' && <EnsWarningMessage onClose={() => setEndMessageState('closed')} />}
        </div>
      </div>
    </div>
  )
}
