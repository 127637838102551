import { ModuleRegistry } from '@ag-grid-community/core'
import { LicenseManager } from '@ag-grid-enterprise/core'

import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model'
import { CsvExportModule } from '@ag-grid-community/csv-export'
import { AdvancedFilterModule } from '@ag-grid-enterprise/advanced-filter'
import { MenuModule } from '@ag-grid-enterprise/menu'
import { ExcelExportModule } from '@ag-grid-enterprise/excel-export'

export function handleAgGridLicense(license: string) {
  ModuleRegistry.registerModules([
    AdvancedFilterModule,
    ServerSideRowModelModule,
    CsvExportModule,
    ExcelExportModule,
    MenuModule,
  ])

  LicenseManager.setLicenseKey(license)
}
