import './App.css'
import { Outlet, useNavigate } from 'react-router-dom'
import { FormSidebarProvider } from './app/components/forms/FormSidebar/state/FormSidebarState'
import FormSidebar from './app/components/forms/FormSidebar'
import { useServiceWorker } from './useServiceWorker'
import { MessageBar, MessageBarButton, MessageBarType } from '@fluentui/react'
import { useTranslation } from 'react-i18next'
import { Navigation } from './app/components/navigation/Navigation.component'
import { useMySettings } from './app/hooks/userSettings/useMySettings'
import React, { useEffect } from 'react'
import i18next from 'i18next'
import { useQueryClient } from '@tanstack/react-query'
import { useMe } from 'app/hooks/me/useMe'
import useCreateConnection from './app/hooks/connection/useCreateConnection'
import { useInitialSetupFlow } from './app/user-journey/initial-setup/useInitialSetupFlow'

const App = () => {
  const queryClient = useQueryClient()

  queryClient.setDefaultOptions({
    queries: {
      enabled: true,
    },
  })

  const { waitingWorker, showReload, reloadPage } = useServiceWorker()
  const { t } = useTranslation('general')
  const { mySettings } = useMySettings()
  const { data: user } = useMe()
  const { mutate } = useCreateConnection()
  const { renderInitialSetupFlow } = useInitialSetupFlow()

  useEffect(() => {
    if (user && user._id) {
      mutate({ userId: user._id })
    }
  }, [user])

  useEffect(() => {
    if (!mySettings) {
      return
    }

    if (mySettings.preferredLanguage) {
      if (mySettings.preferredRegion) {
        i18next.changeLanguage(`${mySettings.preferredLanguage}-${mySettings.preferredRegion}`)
      } else {
        i18next.changeLanguage(mySettings.preferredLanguage)
      }
    }
  }, [mySettings])

  const initialSetupFlow = renderInitialSetupFlow()

  if (initialSetupFlow) {
    return initialSetupFlow
  }

  return (
    <>
      <Navigation />
      {showReload && waitingWorker && (
        <>
          <MessageBar
            styles={{
              content: { width: 'fit-content' },
              root: {
                position: 'absolute',
                display: 'flex',
                justifyContent: 'center',
                zIndex: 99,
              },
            }}
            messageBarType={MessageBarType.warning}
            isMultiline={false}
            dismissButtonAriaLabel={t('CLOSE_LABEL')}
            actions={
              <div>
                <MessageBarButton onClick={() => reloadPage()}>{t('REFRESH_BUTTON')}</MessageBarButton>
              </div>
            }
          >
            {t('NEW_VERSION_MESSAGE')}
          </MessageBar>
        </>
      )}
      <div className="App">
        <FormSidebarProvider>
          <Outlet />
          <FormSidebar />
        </FormSidebarProvider>
      </div>
    </>
  )
}

export default App
