import { FEATURE_NAME, FEATURE_NAMES } from './FeatureName.enum'

export const RoleNames = ['ADMIN', 'ORGANIZATION_ADMIN', 'USER'] as const

export type RoleName = (typeof RoleNames)[number]

export type RoleFeature = {
  [role in RoleName]: Array<FEATURE_NAME>
}

export const roleFeature: RoleFeature = {
  ADMIN: [
    FEATURE_NAMES.ACTIVATION_READ,
    FEATURE_NAMES.ALL_ORGANIZATION_READ,
    FEATURE_NAMES.BILLING_READ,
    FEATURE_NAMES.BUILD_OPERATIONS_LOG_READ,
    FEATURE_NAMES.ELIGIBLE_PRODUCT_CREATE,
    FEATURE_NAMES.ELIGIBLE_PRODUCT_DELETE,
    FEATURE_NAMES.ELIGIBLE_PRODUCT_READ,
    FEATURE_NAMES.ELIGIBLE_PRODUCT_UPDATE,
    FEATURE_NAMES.ENABLED_MICROSERVICE_READ,
    FEATURE_NAMES.ENVIRONMENT_AGENT_SETTINGS_READ,
    FEATURE_NAMES.ENVIRONMENT_AGENT_SETTINGS_UPDATE,
    FEATURE_NAMES.ENVIRONMENT_BASELINE_READ,
    FEATURE_NAMES.ENVIRONMENT_BASELINE_UPDATE,
    FEATURE_NAMES.ENVIRONMENT_CREATE,
    FEATURE_NAMES.ENVIRONMENT_READ,
    FEATURE_NAMES.ENVIRONMENT_UPDATE,
    FEATURE_NAMES.FLOWS_CREATE,
    FEATURE_NAMES.FLOWS_DELETE,
    FEATURE_NAMES.FLOWS_READ,
    FEATURE_NAMES.FLOWS_UPDATE,
    FEATURE_NAMES.HOME_READ,
    FEATURE_NAMES.INTEGRATIONS_CREATE,
    FEATURE_NAMES.INTEGRATIONS_READ,
    FEATURE_NAMES.INTEGRATIONS_UPDATE,
    FEATURE_NAMES.MICROSERVICES_CREATE,
    FEATURE_NAMES.MICROSERVICES_DELETE,
    FEATURE_NAMES.MICROSERVICES_READ,
    FEATURE_NAMES.MICROSERVICES_UPDATE,
    FEATURE_NAMES.MODULE_CREATE,
    FEATURE_NAMES.MODULE_DELETE,
    FEATURE_NAMES.MODULE_READ,
    FEATURE_NAMES.MODULE_UPDATE,
    FEATURE_NAMES.OPERATIONS_READ,
    FEATURE_NAMES.ORGANIZATION_CREATE,
    FEATURE_NAMES.ORGANIZATION_DELETE,
    FEATURE_NAMES.ORGANIZATION_READ,
    FEATURE_NAMES.ORGANIZATION_UPDATE,
    FEATURE_NAMES.ORGANIZATION_PARTNER_UPDATE,
    FEATURE_NAMES.ORGANIZATION_TYPE_UPDATE,
    FEATURE_NAMES.PATCH_MANAGEMENT_CREATE,
    FEATURE_NAMES.PATCH_MANAGEMENT_DELETE,
    FEATURE_NAMES.PATCH_MANAGEMENT_READ,
    FEATURE_NAMES.PATCH_MANAGEMENT_UPDATE,
    FEATURE_NAMES.POLICY_CREATE,
    FEATURE_NAMES.POLICY_DELETE,
    FEATURE_NAMES.POLICY_READ,
    FEATURE_NAMES.POLICY_UPDATE,
    FEATURE_NAMES.REPORTING_GROUP_AGENT_SETTINGS_READ,
    FEATURE_NAMES.REPORTING_GROUP_AGENT_SETTINGS_UPDATE,
    FEATURE_NAMES.REPORTING_GROUP_CREATE,
    FEATURE_NAMES.REPORTING_GROUP_DELETE,
    FEATURE_NAMES.REPORTING_GROUP_READ,
    FEATURE_NAMES.REPORTING_GROUP_UPDATE,
    FEATURE_NAMES.REPORTS_READ,
    FEATURE_NAMES.ROLE_CREATE,
    FEATURE_NAMES.ROLE_DELETE,
    FEATURE_NAMES.ROLE_READ,
    FEATURE_NAMES.ROLE_UPDATE,
    FEATURE_NAMES.TENANT_CREATE,
    FEATURE_NAMES.TENANT_DELETE,
    FEATURE_NAMES.TENANT_READ,
    FEATURE_NAMES.TENANT_UPDATE,
    FEATURE_NAMES.USER_ADMIN_ROLE_UPDATE,
    FEATURE_NAMES.USER_CREATE,
    FEATURE_NAMES.USER_DELETE,
    FEATURE_NAMES.USER_READ,
    FEATURE_NAMES.USER_UPDATE,
    FEATURE_NAMES.WORKSPACES_READ,
    FEATURE_NAMES.WORKSPACE_GROUPS_CREATE,
    FEATURE_NAMES.WORKSPACE_GROUPS_DELETE,
    FEATURE_NAMES.WORKSPACE_GROUPS_READ,
    FEATURE_NAMES.WORKSPACE_GROUPS_UPDATE,
    FEATURE_NAMES.INSTALLED_APPS_READ,
    FEATURE_NAMES.WORKSPACES_V2_READ,
    FEATURE_NAMES.WORKSPACES_V2_UPDATE,
    FEATURE_NAMES.LICENSES_READ,
    FEATURE_NAMES.LICENSES_CREATE,
    FEATURE_NAMES.LICENSES_UPDATE,
    FEATURE_NAMES.ODIN_REPORTS_READ,
    FEATURE_NAMES.VIEW_AS_END_USER,
    FEATURE_NAMES.WORKSPACE_GROUP_SCHEDULE_READ,
    FEATURE_NAMES.WORKSPACES_DETAILS_READ,
    FEATURE_NAMES.BILLING_EDIT_SUBSCRIPTION,
  ],
  ORGANIZATION_ADMIN: [
    FEATURE_NAMES.ACTIVATION_READ,
    FEATURE_NAMES.BILLING_READ,
    FEATURE_NAMES.BUILD_OPERATIONS_LOG_READ,
    FEATURE_NAMES.ELIGIBLE_PRODUCT_READ,
    FEATURE_NAMES.ENABLED_MICROSERVICE_READ,
    FEATURE_NAMES.ENVIRONMENT_LIMITED_AGENT_SETTINGS_READ,
    FEATURE_NAMES.ENVIRONMENT_LIMITED_AGENT_SETTINGS_UPDATE,
    FEATURE_NAMES.ENVIRONMENT_BASELINE_READ,
    FEATURE_NAMES.ENVIRONMENT_READ,
    FEATURE_NAMES.ENVIRONMENT_UPDATE,
    FEATURE_NAMES.FLOWS_CREATE,
    FEATURE_NAMES.FLOWS_DELETE,
    FEATURE_NAMES.FLOWS_READ,
    FEATURE_NAMES.FLOWS_UPDATE,
    FEATURE_NAMES.HOME_READ,
    FEATURE_NAMES.INTEGRATIONS_CREATE,
    FEATURE_NAMES.INTEGRATIONS_READ,
    FEATURE_NAMES.INTEGRATIONS_UPDATE,
    FEATURE_NAMES.MICROSERVICES_CREATE,
    FEATURE_NAMES.MICROSERVICES_DELETE,
    FEATURE_NAMES.MICROSERVICES_READ,
    FEATURE_NAMES.MICROSERVICES_UPDATE,
    FEATURE_NAMES.MODULE_CREATE,
    FEATURE_NAMES.MODULE_READ,
    FEATURE_NAMES.MODULE_UPDATE,
    FEATURE_NAMES.OPERATIONS_READ,
    FEATURE_NAMES.ORGANIZATION_READ,
    FEATURE_NAMES.ORGANIZATION_UPDATE,
    FEATURE_NAMES.PATCH_MANAGEMENT_CREATE,
    FEATURE_NAMES.PATCH_MANAGEMENT_DELETE,
    FEATURE_NAMES.PATCH_MANAGEMENT_READ,
    FEATURE_NAMES.PATCH_MANAGEMENT_UPDATE,
    FEATURE_NAMES.POLICY_CREATE,
    FEATURE_NAMES.POLICY_DELETE,
    FEATURE_NAMES.POLICY_READ,
    FEATURE_NAMES.POLICY_UPDATE,
    FEATURE_NAMES.REPORTING_GROUP_LIMITED_AGENT_SETTINGS_READ,
    FEATURE_NAMES.REPORTING_GROUP_LIMITED_AGENT_SETTINGS_UPDATE,
    FEATURE_NAMES.REPORTING_GROUP_CREATE,
    FEATURE_NAMES.REPORTING_GROUP_DELETE,
    FEATURE_NAMES.REPORTING_GROUP_READ,
    FEATURE_NAMES.REPORTING_GROUP_UPDATE,
    FEATURE_NAMES.REPORTS_READ,
    FEATURE_NAMES.ROLE_CREATE,
    FEATURE_NAMES.ROLE_DELETE,
    FEATURE_NAMES.ROLE_READ,
    FEATURE_NAMES.ROLE_UPDATE,
    FEATURE_NAMES.TENANT_CREATE,
    FEATURE_NAMES.TENANT_DELETE,
    FEATURE_NAMES.TENANT_READ,
    FEATURE_NAMES.TENANT_UPDATE,
    FEATURE_NAMES.USER_CREATE,
    FEATURE_NAMES.USER_DELETE,
    FEATURE_NAMES.USER_READ,
    FEATURE_NAMES.USER_UPDATE,
    FEATURE_NAMES.WORKSPACES_READ,
    FEATURE_NAMES.WORKSPACE_GROUPS_CREATE,
    FEATURE_NAMES.WORKSPACE_GROUPS_DELETE,
    FEATURE_NAMES.WORKSPACE_GROUPS_READ,
    FEATURE_NAMES.WORKSPACE_GROUPS_UPDATE,
    FEATURE_NAMES.INSTALLED_APPS_READ,
    FEATURE_NAMES.LICENSES_READ,
    FEATURE_NAMES.LICENSES_CREATE,
    FEATURE_NAMES.LICENSES_UPDATE,
    FEATURE_NAMES.ODIN_REPORTS_READ,
    FEATURE_NAMES.WORKSPACES_V2_READ,
    FEATURE_NAMES.WORKSPACES_V2_UPDATE,
    FEATURE_NAMES.VIEW_AS_END_USER,
    FEATURE_NAMES.WORKSPACE_GROUP_SCHEDULE_READ,
    FEATURE_NAMES.WORKSPACES_DETAILS_READ,
  ],
  USER: [
    FEATURE_NAMES.BUILD_OPERATIONS_LOG_READ,
    FEATURE_NAMES.ENABLED_MICROSERVICE_READ,
    FEATURE_NAMES.ENVIRONMENT_BASELINE_READ,
    FEATURE_NAMES.ENVIRONMENT_READ,
    FEATURE_NAMES.FLOWS_CREATE,
    FEATURE_NAMES.FLOWS_DELETE,
    FEATURE_NAMES.FLOWS_READ,
    FEATURE_NAMES.FLOWS_UPDATE,
    FEATURE_NAMES.HOME_READ,
    FEATURE_NAMES.MICROSERVICES_READ,
    FEATURE_NAMES.OPERATIONS_READ,
    FEATURE_NAMES.ORGANIZATION_READ,
    FEATURE_NAMES.PATCH_MANAGEMENT_CREATE,
    FEATURE_NAMES.PATCH_MANAGEMENT_DELETE,
    FEATURE_NAMES.PATCH_MANAGEMENT_READ,
    FEATURE_NAMES.PATCH_MANAGEMENT_UPDATE,
    FEATURE_NAMES.POLICY_READ,
    FEATURE_NAMES.REPORTS_READ,
    FEATURE_NAMES.WORKSPACES_READ,
    FEATURE_NAMES.WORKSPACE_GROUPS_READ,
    FEATURE_NAMES.WORKSPACES_DETAILS_READ,
    FEATURE_NAMES.INSTALLED_APPS_READ,
    FEATURE_NAMES.WORKSPACES_V2_READ,
    FEATURE_NAMES.VIEW_AS_END_USER,
  ],
}

export const Roles = {
  ADMIN: 'ADMIN',
  ORGANIZATION_ADMIN: 'ORGANIZATION_ADMIN',
  USER: 'USER',
}

export const roleLocalizationKeyDictionary = {
  ADMIN: 'general:ROLE.ADMIN',
  ORGANIZATION_ADMIN: 'general:ROLE.ORGANIZATION_ADMIN',
  USER: 'general:ROLE.USER',
  PLATFORM: 'general:ROLE.PLATFORM',
}

type RoleRoles = {
  [role in RoleName]: Array<RoleName>
}

export const authorizedRolesForRole: RoleRoles = {
  ADMIN: ['ADMIN', 'ORGANIZATION_ADMIN', 'USER'],
  ORGANIZATION_ADMIN: ['ORGANIZATION_ADMIN', 'USER'],
  USER: ['USER'],
}
