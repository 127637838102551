import React, { createContext, PropsWithChildren, useContext, useEffect } from 'react'
import { DefaultFeaturitUserContext, Featurit } from 'featurit-sdk-js-browser'
import { useMe } from '../../hooks/me/useMe'
import { useGlobalConfig } from './GlobalConfigContext'
import { ViewAsEndUser } from 'app/components/ViewAsEndUserSwitcher/types'
import { useViewAsEndUserStorage } from 'app/components/ViewAsEndUserSwitcher/useViewAsEndUserStorage'
import { FeaturitFlag } from '../../permissions/authorizer/FeatureFlags'

const FeaturitContext = createContext<Featurit>(
  new Featurit({
    tenantIdentifier: '',
    frontendApiKey: '',
    refreshIntervalMinutes: 5,
    sendAnalyticsIntervalMinutes: 1,
    enableAnalytics: false,
  })
)

export const useFeaturit = () => useContext(FeaturitContext)

export const FeaturitProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { data: user } = useMe()
  const { viewAsEndUser, setViewAsEndUser } = useViewAsEndUserStorage()

  const globalContext = useGlobalConfig()

  const featurit = new Featurit({
    tenantIdentifier: globalContext?.featuritTenantName ?? '',
    frontendApiKey: globalContext?.featuritFrontendApiKey ?? '',
    refreshIntervalMinutes: 5,
    sendAnalyticsIntervalMinutes: 1,
    enableAnalytics: false,
  })

  featurit.on(FeaturitFlag.VIEW_AS_END_USER, (v: unknown) => {
    if (typeof v !== 'number' || !ViewAsEndUser[v]) {
      setViewAsEndUser(ViewAsEndUser.OFF)
      return
    }

    setViewAsEndUser(v)
  })

  useEffect(() => {
    if (user) {
      const attributes: [string, string | number][] = [
        ['email', user.email],
        ['businessCode', user.business.uniqueCode!],
        ['viewAsEndUser', viewAsEndUser],
      ]

      const myFeaturitUserContext = new DefaultFeaturitUserContext(
        user._id,
        'my-session-id',
        'my-ip-address',
        new Map(attributes)
      )

      featurit.setUserContext(myFeaturitUserContext)

      featurit.init().catch((error) => console.log(error))
    }
  }, [user, viewAsEndUser])

  return <FeaturitContext.Provider value={featurit}>{children}</FeaturitContext.Provider>
}

type FeatureProps = {
  name: string
}

export const Feature: React.FC<PropsWithChildren<FeatureProps>> = ({ name, children }) => {
  const featurit = useFeaturit()

  if (!featurit.isActive(name)) {
    return <></>
  }

  return <>{children}</>
}
