import './InitialSetupFlow.scss'
import { useTranslation } from 'react-i18next'
import useMediaQuery from '../../../hooks/utils/useMediaQuery'
import { MOBILE_QUERY, TABLET_QUERY } from '../../../config/media-query'
import { Card, FlexxiblePrimaryButton, FlexxibleSecondaryButton, TitleLabel } from '@flexxibleit/flexxible-ui'
import { ProgressIndicator } from '@fluentui/react'
import LogoFlexxible from '../../../assets/img/logo-negative.svg'
import * as React from 'react'
import { useInitialSetupFlow } from '../useInitialSetupFlow'
import { useEffect } from 'react'

export const InitialSetupFlowPage = () => {
  const { t } = useTranslation('user_journey')
  const [isMobile] = useMediaQuery(MOBILE_QUERY)
  const [isTablet] = useMediaQuery(TABLET_QUERY)
  const { initialSetupFlow } = useInitialSetupFlow()
  const stepsOrder = [
    'portal-initial-setup-wizard.welcome-step',
    'portal-initial-setup-wizard.analyzer-step',
    'portal-initial-setup-wizard.workspaces-step',
    'portal-initial-setup-wizard.agent-step',
    'portal-initial-setup-wizard.get-help-step',
  ]

  const [currentStep, setCurrentStep] = React.useState(initialSetupFlow.blocks.find((block) => block.state.active))

  useEffect(() => {
    const activeStep = initialSetupFlow.blocks.find((block) => block.state.active)
    if (activeStep) {
      setCurrentStep(activeStep)
    }
  }, [initialSetupFlow.blocks])

  const isLastStep = () => currentStep?.sid === 'portal-initial-setup-wizard.get-help-step'

  const handleOnClickNext = () => {
    const activeBlock = currentStep
    if (activeBlock) {
      activeBlock.transition('complete')
    }
  }

  const handleOnClickSkip = () => {
    const activeBlock = currentStep

    if (!activeBlock) {
      return
    }

    if (activeBlock.sid === 'portal-initial-setup-wizard.get-help-step') {
      activeBlock.transition('complete')
    } else {
      activeBlock.transition('skip')
    }
  }

  const renderStepsCards = (isMobile?: boolean) => {
    const steps = initialSetupFlow.blocks
      .sort((a, b) => stepsOrder.indexOf(a.sid) - stepsOrder.indexOf(b.sid))
      .map((block) => ({
        title: block.field<string>('title') ?? '',
        subtitle: block.field<string>('subtitle') ?? '',
        description: block.field<string>('description') ?? '',
        active: block.state.active,
        image: block.field<string>('image') ?? '',
      }))

    if (isMobile) {
      const step = steps.find((step) => step.active)
      if (!step) return null
      return (
        <Card
          key={step.title}
          cardProps={{
            style: {
              width: '90%',
              borderRadius: '5px',
              border: step.active ? '1px solid #FFA500' : '1px solid #E5E5E5',
              boxShadow: step.active ? '0px 4px 4px var(--theme-primary)' : 'none',
              backgroundColor: step.active ? 'var(--theme-lighter-alt)' : 'white',
              maxHeight: '90%',
            },
          }}
        >
          <h2>{t(`INITIAL_SETUP_FLOW.CONTENT_SECTION.${step.title}`)}</h2>
          <TitleLabel>{t(`INITIAL_SETUP_FLOW.CONTENT_SECTION.${step.subtitle}`)}</TitleLabel>
          {step.active && step.image !== '' && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <img
                className={'initial-setup-mobile__media__image'}
                src={currentStep?.field('image') as string}
                alt={'step-image'}
              />
            </div>
          )}
          <p>{t(`INITIAL_SETUP_FLOW.CONTENT_SECTION.${step.description}`)}</p>
        </Card>
      )
    }

    return steps.map((step, index) => (
      <Card
        key={index}
        cardProps={{
          style: {
            width: '70%',
            margin: '0 auto',
            borderRadius: '5px',
            border: step.active ? '1px solid #FFA500' : '1px solid #E5E5E5',
            boxShadow: step.active ? '0px 4px 4px var(--theme-primary)' : 'none',
            backgroundColor: step.active ? 'var(--theme-lighter-alt)' : 'white',
            zIndex: 1,
          },
        }}
      >
        <h2>{t(`INITIAL_SETUP_FLOW.CONTENT_SECTION.${step.title}`)}</h2>
        <p>{t(`INITIAL_SETUP_FLOW.CONTENT_SECTION.${step.subtitle}`)}</p>
      </Card>
    ))
  }

  if (isMobile || isTablet) {
    return (
      <div className={'initial-setup-mobile'}>
        <img className={'initial-setup-mobile__media__logo'} src={LogoFlexxible} alt={'Flexxible-logo'} />
        <h1 className={'initial-setup-mobile__media__title'}>
          <span
            style={{
              color: 'var(--white)',
            }}
          >
            {t('INITIAL_SETUP_FLOW.MEDIA_SECTION.TITLE_1')}
          </span>
          <span color={'var(--theme-primary)'}>{t('INITIAL_SETUP_FLOW.MEDIA_SECTION.TITLE_2')}</span>
        </h1>
        <div className={'initial-setup-mobile__content__cards'}>{renderStepsCards(true)}</div>
        <div className={'initial-setup-mobile__footer'}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
              padding: '10px',
              height: '25px',
              borderTop: '1px solid #E5E5E5',
            }}
          >
            <ProgressIndicator
              styles={{
                root: {
                  width: '100px',
                },
                progressBar: {
                  height: '10px',
                  backgroundColor: 'var(--theme-primary)',
                },
                progressTrack: {
                  height: '10px',
                },
              }}
              percentComplete={
                initialSetupFlow.blocks.filter((block) => block.state.entered).length / initialSetupFlow.blocks.length
              }
            />
            <span>
              {initialSetupFlow.blocks.filter((block) => block.state.entered).length}/{initialSetupFlow.blocks.length}
            </span>
            <div className={'initial-setup-mobile__content__buttons'}>
              <>
                {!isLastStep() && (
                  <FlexxibleSecondaryButton onClick={handleOnClickSkip}>
                    {t('INITIAL_SETUP_FLOW.CONTENT_SECTION.SKIP_BUTTON')}
                  </FlexxibleSecondaryButton>
                )}
                <FlexxiblePrimaryButton
                  className={'initial-setup__content__buttons__button'}
                  onClick={handleOnClickNext}
                >
                  {!isLastStep()
                    ? t('INITIAL_SETUP_FLOW.CONTENT_SECTION.CONTINUE_BUTTON')
                    : t('INITIAL_SETUP_FLOW.CONTENT_SECTION.LAST_STEP_BUTTON')}
                </FlexxiblePrimaryButton>
              </>
            </div>
          </div>
        </div>
        <img
          className={'initial-setup-mobile__media__background_logo'}
          src={
            'https://assets-global.website-files.com/658182fae46a1b3f991325ae/65998f914415cad293fa6d81_636932c66c32ea2f56e4b603_BG%20ICON.svg'
          }
          alt={'Initial-setup'}
        />
      </div>
    )
  }

  return (
    <div className={'initial-setup'}>
      <div className={'initial-setup__content'}>
        <h1 className={'initial-setup__content__title'}>{t('INITIAL_SETUP_FLOW.CONTENT_SECTION.TITLE')}</h1>
        <p className={'initial-setup__content__description'}>{t('INITIAL_SETUP_FLOW.CONTENT_SECTION.DESCRIPTION')}</p>
        <div className={'initial-setup__content__cards'}>{renderStepsCards()}</div>
        <div className={'initial-setup__footer'}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
              padding: '20px',
              height: '20px',
              borderTop: '1px solid #E5E5E5',
            }}
          >
            <ProgressIndicator
              styles={{
                root: {
                  width: '150px',
                },
                progressBar: {
                  height: '10px',
                  backgroundColor: 'var(--theme-primary)',
                },
                progressTrack: {
                  height: '10px',
                },
              }}
              percentComplete={
                initialSetupFlow.blocks.filter((block) => block.state.entered).length / initialSetupFlow.blocks.length
              }
            />
            <span>
              {initialSetupFlow.blocks.filter((block) => block.state.entered).length}/{initialSetupFlow.blocks.length}
            </span>
            <div className={'initial-setup__content__buttons'}>
              {isLastStep() ? (
                <>
                  <FlexxiblePrimaryButton
                    className={'initial-setup__content__buttons__button'}
                    onClick={handleOnClickNext}
                  >
                    {t('INITIAL_SETUP_FLOW.CONTENT_SECTION.LAST_STEP_BUTTON')}
                  </FlexxiblePrimaryButton>
                </>
              ) : (
                <>
                  <FlexxibleSecondaryButton onClick={handleOnClickSkip}>
                    {t('INITIAL_SETUP_FLOW.CONTENT_SECTION.SKIP_BUTTON')}
                  </FlexxibleSecondaryButton>
                  <FlexxiblePrimaryButton
                    className={'initial-setup__content__buttons__button'}
                    onClick={handleOnClickNext}
                  >
                    {t('INITIAL_SETUP_FLOW.CONTENT_SECTION.CONTINUE_BUTTON')}
                  </FlexxiblePrimaryButton>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className={'initial-setup__media'}>
        <img className={'initial-setup__media__logo'} src={LogoFlexxible} alt={'Flexxible-logo'} />
        <h1 className={'initial-setup__media__title'}>
          <span
            style={{
              color: 'var(--white)',
            }}
          >
            {t('INITIAL_SETUP_FLOW.MEDIA_SECTION.TITLE_1')}
          </span>
          <span color={'var(--theme-primary)'}>{t('INITIAL_SETUP_FLOW.MEDIA_SECTION.TITLE_2')}</span>
        </h1>
        {currentStep?.field('image') && (
          <img
            className={'initial-setup__media__step_image'}
            src={currentStep?.field('image') as string}
            alt={'step-image'}
          />
        )}
        <img
          className={'initial-setup__media__background_logo'}
          src={
            'https://assets-global.website-files.com/658182fae46a1b3f991325ae/65998f914415cad293fa6d81_636932c66c32ea2f56e4b603_BG%20ICON.svg'
          }
          alt={'Initial-setup'}
        />
        {currentStep && (
          <p className={'initial-setup__media__description'}>
            {t(`INITIAL_SETUP_FLOW.CONTENT_SECTION.${currentStep?.field('description') as string}`)}
          </p>
        )}
      </div>
    </div>
  )
}
