export const viewAsEndUserSwitcherStyles = (isOn: boolean) => ({
  label: {
    color: 'white',
    fontWeight: 'normal',
  },
  pill: {
    borderColor: 'var(--neutral-primary)',
    backgroundColor: 'transparent',
  },
  thumb: { backgroundColor: isOn ? '#ff6500' : '#6d7278' },
})
