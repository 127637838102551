export const WorkspacePermissionNames = [
    'NO_ACCESS',
    'L1_SUPPORT_TEAM_READ_ONLY',
    'L1_SUPPORT_TEAM',
    'L2_SUPPORT_TEAM_READ_ONLY',
    'L2_SUPPORT_TEAM',
    'L3_ENGINEERING_TEAM_READ_ONLY',
    'L3_ENGINEERING_TEAM',
    'ADMIN_READ_ONLY',
    'ADMIN'
] as const

export type WorkspacePermissionName = (typeof WorkspacePermissionNames)[number]

export const WorkspacePermissions = {
    NO_ACCESS: 'NO_ACCESS',
    L1_SUPPORT_TEAM_READ_ONLY: 'L1_SUPPORT_TEAM_READ_ONLY',
    L1_SUPPORT_TEAM: 'L1_SUPPORT_TEAM',
    L2_SUPPORT_TEAM_READ_ONLY: 'L2_SUPPORT_TEAM_READ_ONLY',
    L2_SUPPORT_TEAM: 'L2_SUPPORT_TEAM',
    L3_ENGINEERING_TEAM_READ_ONLY: 'L3_ENGINEERING_TEAM_READ_ONLY',
    L3_ENGINEERING_TEAM: 'L3_ENGINEERING_TEAM',
    ADMIN_READ_ONLY: 'ADMIN_READ_ONLY',
    ADMIN: 'ADMIN'
}

  

export const workspacePermissionLocalizationKeyDictionary = {
    NO_ACCESS: 'USERS.WORKSPACE_PERMISSION.NO_ACCESS',
    L1_SUPPORT_TEAM_READ_ONLY: 'USERS.WORKSPACE_PERMISSION.L1_RO',
    L1_SUPPORT_TEAM: 'USERS.WORKSPACE_PERMISSION.L1',
    L2_SUPPORT_TEAM_READ_ONLY: 'USERS.WORKSPACE_PERMISSION.L2_RO',
    L2_SUPPORT_TEAM: 'USERS.WORKSPACE_PERMISSION.L2',
    L3_ENGINEERING_TEAM_READ_ONLY: 'USERS.WORKSPACE_PERMISSION.L3_RO',
    L3_ENGINEERING_TEAM: 'USERS.WORKSPACE_PERMISSION.L3',
    ADMIN_READ_ONLY: 'USERS.WORKSPACE_PERMISSION.ADMIN_RO',
    ADMIN: 'USERS.WORKSPACE_PERMISSION.ADMIN',
}



export const hasEnoughWorkspacesPermissions = (
  authWorkspace: WorkspacePermissionName,
  requestWorkspace: WorkspacePermissionName
): boolean => {
  const orderedWorkspacesPermissions = [
    WorkspacePermissions.ADMIN,
    WorkspacePermissions.ADMIN_READ_ONLY,
    WorkspacePermissions.L3_ENGINEERING_TEAM,
    WorkspacePermissions.L3_ENGINEERING_TEAM_READ_ONLY,
    WorkspacePermissions.L2_SUPPORT_TEAM,
    WorkspacePermissions.L2_SUPPORT_TEAM_READ_ONLY,
    WorkspacePermissions.L1_SUPPORT_TEAM,
    WorkspacePermissions.L1_SUPPORT_TEAM_READ_ONLY,
    WorkspacePermissions.NO_ACCESS
  ]
  const authIndex = orderedWorkspacesPermissions.indexOf(authWorkspace)
  const requestIndex = orderedWorkspacesPermissions.indexOf(requestWorkspace)
  return requestIndex >= authIndex;
  
}