import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { BusinessType } from 'app/models/business/BusinessType'
import { gql } from 'graphql-request'
import graphClient from '../../query-client/graphClient'

const getOrganizationsForSelectionQuery = gql`
  query getBusiness($onlyActive: Boolean) {
    getBusiness(onlyActive: $onlyActive) {
      _id
      name
      type
      isTrial
      active
      uniqueCode
      delegatedOrganizations
      isDelegatedOrganization
      modules {
        type
        url
      }
    }
  }
`

interface Response {
  getBusiness: SelectableOrganization[]
}

export interface SelectableOrganization {
  _id: string
  name: string
  type: BusinessType
  isTrial: boolean
  active: boolean
  uniqueCode: string
  modules: { type: string; url: string }[]
  delegatedOrganizations: string[]
  isDelegatedOrganization: boolean
}

async function getOrganizationsForSelectionV2(onlyActive = false): Promise<SelectableOrganization[]> {
  const organizations = await graphClient
    .request<Response>(getOrganizationsForSelectionQuery, { onlyActive })
    .then((response) => response.getBusiness)

  const mapMainOrganizations = organizations
    .filter((org) => org.delegatedOrganizations?.length)
    .reduce<Record<string, string>>((acc, org) => {
      const delegated = org.delegatedOrganizations
        .filter((id) => id !== org._id)
        .reduce((acc, orgId) => {
          return { ...acc, [orgId]: org.name }
        }, {})

      return {
        ...acc,
        ...delegated,
      }
    }, {})

  return organizations
    .map((org) => {
      return {
        ...org,
        name: mapMainOrganizations[org._id] ? `${mapMainOrganizations[org._id]} > ${org.name}` : org.name,
      }
    })
    .sort((orgA: SelectableOrganization, orgB: SelectableOrganization) => {
      return orgA.name.toLowerCase().localeCompare(orgB.name.toLowerCase())
    })
}

export function useGetOrganizationsForSelection(onlyActive = false): UseQueryResult<SelectableOrganization[]> {
  return useQuery(
    ['business', { forSelection: true, onlyActive }],
    () => {
      return getOrganizationsForSelectionV2(onlyActive)
    },
    { refetchOnWindowFocus: false }
  )
}
