import { ReactNode } from 'react'
import { IPanelStyles, Panel, PanelType } from '@fluentui/react/lib/Panel'
import { IPanelProps } from '@fluentui/react'
import useMediaQuery from 'app/hooks/utils/useMediaQuery';
import { MOBILE_QUERY } from 'app/config/media-query';

export interface LateralPanelProps extends IPanelProps {
    handleOnClose: () => void;
    isPanelOpen: boolean;
    title: string;
    children: ReactNode;
}



export const LateralPanel = ({ children, isPanelOpen, title, handleOnClose, ...rest }: Partial<LateralPanelProps>) => {
    const [ isMobile ] = useMediaQuery(MOBILE_QUERY)

    const panelStyles: Partial<IPanelStyles> = {
        main: {
            backgroundColor: '#FAF9F8',
        },
        overlay: {
            backgroundColor: 'rgba(0,0,0,0.4)',
            backdropFilter: 'blur(3px)',
        },
        commands: {
            backgroundColor: 'var(--neutral-lighter-alt)'
        },
        header: {
            borderBottom: '1px solid #EAEAEA',
            padding: '0, 0, 8px',
            marginLeft: '24px'
        },
        content: {
            padding: isMobile ? '0 10px 20px 10px': '0 24px 20px 24px',
        },
        subComponentStyles: {
            closeButton: {
                root: {
                    height: '16px',
                    width: '16px',
                },
    
            }
        }
    }

    return (
        <Panel
            className='lateral-panel'
            isLightDismiss
            isOpen={isPanelOpen}
            onDismiss={handleOnClose}
            closeButtonAriaLabel="Close"
            headerText={title}
            styles={panelStyles}
            type={PanelType.custom}
            customWidth={'480px'}
            {...rest}
        >
            {children}
        </Panel>
    )
}



