export const enum GettingStartedChecklistTasks {
  CREATE_REPORTING_GROUP = 'portal-getting-started-checklist.create-reporting-group-task',
  DOWNLOAD_AGENT = 'portal-getting-started-checklist.download-agent-task',
  CONSUMPTION = 'portal-getting-started-checklist.consumption-task',
  NAVIGATE_DIFFERENT_MODULE_TASK = 'portal-getting-started-checklist.navigate-different-module-task',
  INVITE_PEOPLE = 'portal-getting-started-checklist.invite-people-task',
  ENABLE_MICROSERVICE = 'portal-getting-started-checklist.enable-microservice-task',
  CREATE_TENANT = 'portal-getting-started-checklist.create-tenant-task',
  ASSIGN_POLICY = 'portal-getting-started-checklist.assign-policy-task',
}
