import { useAuth } from '../../../globals/useAuth'
import { useTranslation } from 'react-i18next'
import { useAuth0 } from '@auth0/auth0-react'
import { ContextMenuButton } from '../user-context-menu-button/ContextMenuButton'

export const LogoutFeature = () => {
  const { t } = useTranslation()
  const { logout: auth0Logout } = useAuth0()
  const auth = useAuth()

  const handleLogout = () => {
    auth.logout()
    auth0Logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    })
  }
  return (
    <ContextMenuButton
      onClick={handleLogout}
      name={t('general:SIGN_OUT')}
      icon={'SignOut'}
    />
  )
}
