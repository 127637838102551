import { Controller, ControllerProps, useFormContext } from "react-hook-form";

function Controlled(props: ControllerProps) {
  const { control } = useFormContext()
  return (
    <>
      <Controller {...props} control={control} />
    </>
  )
}

export default Controlled;