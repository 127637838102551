import { GraphQLClient } from 'graphql-request'
import { ClientError, Response } from 'graphql-request/dist/types'
import { GRAPH_BASE_URL, STORAGE_USER_KEY } from '../config/Consts'

const unauthenticatedMessages = ['Invalid jwt', 'jwt must be provided', 'You are not authenticated.']

function requestMiddleware(request: any) {
  const item: any = localStorage.getItem(STORAGE_USER_KEY)
  const token = JSON.parse(item)?.token || ''
  return {
    ...request,
    headers: { ...request.headers, Authorization: `Bearer ${token}` },
  }
}

function responseMiddleware(response: Response<unknown> | Error) {
  if (response instanceof Error && response instanceof ClientError) {
    if (unauthenticatedMessages.includes(response.response.errors?.[0].message ?? '')) {
      window.location.href = '/login'
    }
  }
}

export default new GraphQLClient(GRAPH_BASE_URL, {
  requestMiddleware,
  responseMiddleware,
});
