export enum FeaturitFlag {
  DELEGATED_TENANTS = 'DELEGATED_TENANTS',
  API_KEYS_V2 = 'API_KEYS_V2',
  POLICY_V2 = 'POLICY_V2',
  WORKSPACES_GROUPS_DETAILS_WORKSPACES_V2 = 'WORKSPACES_GROUPS_DETAILS_WORKSPACES_V2',
  ORGANIZATION_LIST_V2 = 'ORGANIZATION_LIST_V2',
  VIEW_NUMBER_OF_EXECUTIONS_IN_ODIN_REPORTS = 'VIEW_NUMBER_OF_EXECUTIONS_IN_ODIN_REPORTS',
  VIEW_PERMISSIONS_ACTION_ITEM_IN_ROLE_TABLE = 'VIEW_PERMISSIONS_ACTION_ITEM_IN_ROLE_TABLE',
  INSTALLED_APPS = 'INSTALLED_APPS',
  LICENSES = 'LICENSES',
  PATCH_POLICY_TARGETS_V2 = 'PATCH_POLICY_TARGETS_V2',
  WORKSPACES_V2 = 'WORKSPACES_V2',
  ODIN_REPORTS = 'ODIN_REPORTS',
  WORKSPACE_GROUP_SCHEDULE = 'WORKSPACE_GROUP_SCHEDULE',
  VIEW_AS_END_USER = 'VIEW_AS_END_USER',
  ODIN_BILLING = 'ODIN_BILLING',
  ODIN_BILLING_SUBSCRIPTION_CREATE = 'ODIN_BILLING_SUBSCRIPTION_CREATE',
}
