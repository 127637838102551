import './Badge.scss'

type BadgeProps = {
  children: React.ReactNode
}

export const Badge = ({ children, ...props }: BadgeProps) => {
  return (
    <span className='badge-component' {...props}>
      {children}
    </span>
  )
}
