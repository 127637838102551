import { IButtonProps, IButtonStyles, PrimaryButton } from "@fluentui/react";
import './status-layout.scss';
export interface EmptyMessageProps {
  img: any
  imgWithContainer?: boolean
  message: string
  renderButton?: any
  buttonProps?: IButtonProps
  imgStyles?: any
  explanation?: string | undefined
}

const buttonStyles: IButtonStyles = {
  label: {
    fontWeight: 400
  }
}

const StatusLayout = ({ img, message, explanation, imgWithContainer = false, renderButton, buttonProps, imgStyles }: EmptyMessageProps) => {
  return (
    <div className="d-flex w-100 d-center" style={ {height: 'calc(100% - 32px)'} }>
      <div className="d-flex d-flexGrow1 d-flexCol d-center ">
        <div className={`d-flex d-center ${ imgWithContainer ? 'img-container-circle' : ''}` }>
          {(typeof img === 'object') ? (
            img
          ) : (
            <img src={ img } style={ imgStyles } alt="status" />
          )}
        </div>
        <span className='bold-text vertical-margin-3' style={ { textAlign: 'center' } }>
          { message }
        </span>
        { explanation && <span style={{ textAlign: 'center' }}>{ explanation }</span> }
        { renderButton ? renderButton : buttonProps && <PrimaryButton {...buttonProps} styles={ buttonStyles }/> }
      </div>
    </div>
  )
}

export default StatusLayout