import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import graphClient from '../../query-client/graphClient';
import { useAuth } from '../../globals/useAuth';
import { useLocation, useNavigate } from 'react-router-dom';
import { AuthUser } from '../../globals/interfaces'



export const loginQuery: any = gql`
    mutation loginV2($organizationId: String!, $token: String!) {
        loginV2(organizationId: $organizationId, token: $token) {
            token
            user {
                _id
                email
                language
                role
                workspacePermission
            }
        }
    }
`;

export const useLoginV2 = (): UseMutationResult<any, any, any> => {
  const navigate = useNavigate()
  const location = useLocation()
  const from = location.state?.from?.pathname || '/'
  const { setUser } = useAuth();
  const loginV2 = async ({ organizationId, token }: any) => {
    return graphClient.request(loginQuery, { organizationId, token })
  }

  return useMutation(
    {
      mutationKey: [ 'loginV2' ],
      mutationFn: loginV2,
      retry: 3,
      onSuccess: (data: any) => {
        const { token, user: { email, language, role: rol, workspacePermission } } = data.loginV2;
        setUser({
          token,
          email,
          language,
          rol,
          workspacePermission
        } as AuthUser)
        navigate(from, { replace: true });
      },
    });
};