import { Icon } from '@fluentui/react'
import { Business } from '../../../query-client/types'
import { theme } from '../../../config/theme'
import { TFunction } from 'react-i18next'
import { Badge } from 'app/components/Badge/Badge'
import { getOrganizationTypeIcon } from 'app/services/organization-icon-service'
import { BusinessType } from 'app/models/business/BusinessType'
import { SelectableOrganization } from 'app/hooks/organization/useGetOrganizationsForSelection'

export const renderOrganizationSelectorPlaceholder = (
  t: TFunction,
  isMobileHeader: boolean,
  selectedOrganization: SelectableOrganization,
  userOrganization: Business
): JSX.Element | null => {
  const getSubtitle = (type: BusinessType): string => {
    switch (type) {
      case BusinessType.END_CUSTOMER:
        return t('general:ORGANIZATION_SELECTION.CLIENT_ORGANIZATION')
      case BusinessType.PARTNER:
        return t('general:ORGANIZATION_SELECTION.PARTNER_ORGANIZATION')
      case BusinessType.WHOLESALER:
        return t('general:ORGANIZATION_SELECTION.WHOLESALER_ORGANIZATION')
      default:
        return ''
    }
  }

  if (isMobileHeader) {
    return (
      <Icon
        iconName={'AccountManagement'}
        style={{
          color: theme.palette?.neutralLighter,
          fontSize: '15px',
        }}
      />
    )
  }

  if (!selectedOrganization) {
    return null
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: '3px 8px',
        width: '100%',
      }}
    >
      <div className="organization_type_img">
        {selectedOrganization.type && getOrganizationTypeIcon(selectedOrganization.type)}
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          width: '100%',
          overflow: 'hidden',
        }}
      >
        <div
          style={{
            color: theme.palette?.neutralLighter,
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            paddingRight: '8px',
          }}
        >
          {selectedOrganization.name}
        </div>

        {userOrganization && selectedOrganization && (
          <div
            style={{
              color: theme.palette?.neutralTertiaryAlt,
            }}
          >
            {selectedOrganization.isTrial && <Badge>{t('organizations:TRIAL')}</Badge>}

            {userOrganization._id === selectedOrganization._id
              ? t('general:ORGANIZATION_SELECTION.MY_ORGANIZATION')
              : getSubtitle(selectedOrganization.type)}
          </div>
        )}
      </div>
      <div style={{ marginRight: '10px', color: '#8c9094' }}>
        <label>(Ctrl + K)</label>
      </div>
    </div>
  )
}
