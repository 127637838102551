import { useQuery, UseQueryResult } from '@tanstack/react-query'
import graphClient from '../../query-client/graphClient'
import { gql } from 'graphql-request'
import { MicroserviceAction } from '../../models/microservices/MicroserviceAction'
import { MultiLanguageField } from 'app/models/microservices'

export interface EnabledMicroserviceView {
  id: string
  name: MultiLanguageField[]
  description: MultiLanguageField[]
  category: MultiLanguageField[]
  iconName: string
  iconBackground: string
  organization: {
    name: string
    isTrial: boolean
  }
  createdAt: Date
  gracePeriod: Date
  permissions: MicroserviceAction[]
}

interface Response {
  getEnabledMicroservices: Array<EnabledMicroserviceView>
}

interface Variables {
  organizationId: string
}

const getMicroservicesQuery = gql`
  query ($organizationId: ID!) {
    getEnabledMicroservices(organizationId: $organizationId) {
      id
      name {
        code
        text
        translatedByIA
      }
      description {
        code
        text
        translatedByIA
      }
      category {
        code
        text
        translatedByIA
      }
      iconName
      iconBackground
      organization {
        name
        isTrial
      }
      createdAt
      gracePeriod
      permissions
    }
  }
`

export async function getEnabledMicroservices(organizationId: string): Promise<EnabledMicroserviceView[]> {
  const response = await graphClient.request<Response, Variables>(getMicroservicesQuery, {
    organizationId,
  })
  return response.getEnabledMicroservices
}

export const useGetEnabledMicroservices = (organizationId?: string): UseQueryResult<EnabledMicroserviceView[]> => {
  if (!organizationId) {
    return useQuery(['enabled-microservices'], () => [], {
      refetchOnWindowFocus: false,
      enabled: false,
    })
  }

  return useQuery(['enabled-microservices', organizationId], () => getEnabledMicroservices(organizationId), {
    refetchOnWindowFocus: false,
  })
}
