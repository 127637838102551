import { useLocalStorage } from '../../hooks/utils/useLocalStorage'
import { STORAGE_I18N, STORAGE_USER_KEY } from '../../config/Consts'
import { AuthUser } from '../interfaces'

export interface GlobalContextAuth {
  user: AuthUser | null;
  setUser: (user: AuthUser) => void;
  logout: () => void;
}

export const globalContextAuth = (): GlobalContextAuth => {
  const [user, setUser] = useLocalStorage<AuthUser | null>(STORAGE_USER_KEY, null)

  const logout = () => {
    setUser(null)
    sessionStorage.clear()
    localStorage.removeItem(STORAGE_I18N)
  }

  return {
    user,
    setUser,
    logout,
  }
}
