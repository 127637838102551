import { useTranslation } from 'react-i18next'
import './EnsWarningMessage.scss'
import { Icon } from '@fluentui/react'


export function EnsWarningMessage({onClose}: {onClose():void}) {
  const { t } = useTranslation('general')
  
  return (
    <div className="ens-message">
      <div onClick={onClose} className="ens-message__icon">
        <Icon iconName="Cancel" style={{ fontWeight: 'bold', fontSize: '12px' }} />
      </div>
      <p className="ens-message__text">{t('ENS_MESSAGE')}</p>
    </div>
  )
}
