import { Dropdown } from '@fluentui/react/lib/Dropdown'
import * as React from 'react'
import { useEffect, useRef, useState } from 'react'
import {
  IDropdownOption,
  IDropdownProps,
  ISearchBox,
  ISearchBoxProps,
  SearchBox,
} from '@fluentui/react'
import { theme } from 'app/config/theme'
import { IDropdown } from '@fluentui/react/lib-amd'

interface Props {
  dropdownProps: IDropdownProps
  searchBoxProps: ISearchBoxProps
  fixedSearchBox: boolean
  searchBoxPosition: SearchBoxPosition
  componentRef: React.MutableRefObject<IDropdown | null>
  searchRef: React.MutableRefObject<ISearchBox | null>
}

export enum SearchBoxPosition {
  TOP = 'top',
  BOTTOM = 'bottom',
}

type orgnizationSearchBoxType = {
  fixedSearchBox: boolean
  searchBoxPosition: SearchBoxPosition
  searchBoxProps: ISearchBoxProps
  handleOnSearchBoxChange: (e: React.ChangeEvent<HTMLInputElement> | undefined, value: string | undefined) => void
  searchRef: React.MutableRefObject<ISearchBox | null>
  handleOnSearchWithEnter: (_: string) => void
  dropdownRef: React.MutableRefObject<IDropdown | null>
}

const OrganizationSearchBox = ({ fixedSearchBox, searchBoxPosition, searchBoxProps, dropdownRef, handleOnSearchBoxChange, searchRef, handleOnSearchWithEnter }: orgnizationSearchBoxType) => {

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if ((event.ctrlKey || event.metaKey) && event.key === 'k') {
      event.preventDefault();
      itemRef.current
      dropdownRef.current?.dismissMenu();
    }
  };

  const itemRef = useRef<React.Ref<HTMLDivElement> | undefined>(null);

  const searchBoxStyles: React.CSSProperties = !fixedSearchBox
    ? { margin: '8px' }
    : {
      padding: '8px',
      position: 'sticky',
      ...(searchBoxPosition === SearchBoxPosition.TOP ? { top: '0px' } : { bottom: '0px' }),
      background: theme.palette?.neutralPrimary,
      zIndex: 1,
    }
  return <div style={searchBoxStyles}>
    <SearchBox
      id={'search-box-selector'}
      {...searchBoxProps}
      disableAnimation={true}
      onChange={handleOnSearchBoxChange}
      componentRef={searchRef}
      onSearch={handleOnSearchWithEnter}
      onKeyDown={handleKeyDown}
    />
  </div>
}

/**
 * Version of SearchableDropdown that allows for a fixed search box
 * Use multiSelect but only allow one option to be selected and no losing focus
 */
export const SearchableDropdownAlt = ({ dropdownProps, searchBoxProps, fixedSearchBox = false, searchBoxPosition = SearchBoxPosition.TOP, componentRef, searchRef }: Props) => {
  const [dropdownOptionsState, setDropdownOptionsState] = useState<
    IDropdownOption[]
  >([...dropdownProps.options])

  useEffect(() => {
    setDropdownOptionsState(dropdownProps.options)
  }, [dropdownProps.options])

  const handleOnSearchBoxChange = (
    e: React.ChangeEvent<HTMLInputElement> | undefined,
    value: string | undefined,
  ) => {
    // Workaround: Sometimes the dropdown loses focus
    setTimeout(() => {
      e?.target?.focus()
    }, 100)
    const selectedKey = dropdownProps.selectedKey ?? dropdownProps.selectedKey?.[0]
    const filteredOptions = dropdownProps.options.filter((option: IDropdownOption) => {
      return (
        option.text?.toLowerCase().indexOf(value!.toLowerCase()) !== -1 ||
        option.key === selectedKey
      )
    })
    setDropdownOptionsState(filteredOptions)
  }

  const handleOnSearchWithEnter = (_: string) => {
    if (dropdownOptionsState.length === 1) {
      dropdownProps && dropdownProps.onChange && dropdownProps.onChange({} as React.FormEvent<HTMLDivElement>, dropdownOptionsState[0])
    }
  }

  const renderList = (dropdownProps: any, defaultRender: any) => {
    const searchBox = <OrganizationSearchBox
      {...{
        fixedSearchBox,
        searchBoxPosition,
        searchBoxProps,
        searchRef,
        handleOnSearchBoxChange,
        handleOnSearchWithEnter,
        dropdownRef: componentRef
      }}

    />

    return (
      <>
        {searchBoxPosition === SearchBoxPosition.TOP && searchBox}
        <div
          onKeyDown={(e) => {
            if (e.key.match(/^[a-zA-Z0-9]$/)) {
              e.preventDefault();
              searchRef.current?.focus();
              setTimeout(() => {
                const searchBoxField = document.querySelector('#search-box-selector') as HTMLInputElement;
                searchBoxField.value = e.key.toString();
              })
            }
          }}
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {defaultRender!(dropdownProps)}
        </div>
        {searchBoxPosition === SearchBoxPosition.BOTTOM && searchBox}
      </>
    )
  }

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if ((event.ctrlKey || event.metaKey) && event.key === 'k') {
        handleOpenOrganizationSelector();
      }
    }

    window.addEventListener('keydown', handleKeyDown)
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [dropdownProps.options])

  const handleOpenOrganizationSelector = () => {
    setDropdownOptionsState([...dropdownProps.options])
    setTimeout(() => searchRef.current?.focus(), 300)
  }

  return (
    <Dropdown
      {...dropdownProps}
      styles={{ ...dropdownProps.styles }}
      options={dropdownOptionsState}
      onRenderList={renderList}
      onFocus={() => setTimeout(() => searchRef.current?.focus(), 300)}
      onClick={handleOpenOrganizationSelector}
      componentRef={componentRef}
      onDismiss={() => {
        componentRef.current?.dismissMenu()
      }}
      onKeyDown={(e) => {
        e.preventDefault()
      }}
    />
  )
}
