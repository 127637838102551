import { theme } from '../config/theme'
import { RELEASE_BRANCH, RELEASE_BRANCH_COMMIT, RELEASE_ENVIRONMENT, RELEASE_LAST_UPDATED } from '../config/Consts'
import LogoFlexxible from '../assets/img/logo-negative.svg'
import { Icon } from '@fluentui/react'
import useMediaQuery from '../hooks/utils/useMediaQuery'
import { MOBILE_QUERY } from '../config/media-query'
import { Environment } from '../config/environment.enum'
import './Banner.scss'

interface ReleaseDataItem {
  name: string
  value?: string
  iconName: string
  showInMobile?: boolean
}

export const ReleaseBanner = () => {
  const [isMobile] = useMediaQuery(MOBILE_QUERY)

  const releaseDataItems: ReleaseDataItem[] = [
    {
      name: 'Environment',
      value: RELEASE_ENVIRONMENT,
      iconName: 'World',
      showInMobile: true,
    },
    {
      name: 'Release',
      value: RELEASE_BRANCH,
      iconName: 'BranchPullRequest',
      showInMobile: true,
    },
    {
      name: 'Commit',
      value: RELEASE_BRANCH_COMMIT,
      iconName: 'BranchCommit',
      showInMobile: false,
    },
    {
      name: 'Last updated',
      value: RELEASE_LAST_UPDATED,
      iconName: 'TimeEntry',
      showInMobile: false,
    },
  ]

  const renderReleaseItem = (item: ReleaseDataItem, index: number) => {
    if ((!item.showInMobile && isMobile) || !item.value) {
      return null
    }
    return (
      <div
        key={index}
        style={{
          display: 'flex',
          gap: '5px',
          alignItems: 'center',
          paddingRight: '10px',
        }}
      >
        <Icon
          iconName={item.iconName}
          style={{
            fontSize: '1rem',
            color: theme.palette?.themePrimary,
          }}
        />
        <span
          style={{
            color: theme.palette?.white,
          }}
        >
          {item.value}
        </span>
      </div>
    )
  }

  const renderLogo = () => {
    if (isMobile) {
      return null
    }
    return (
      <div
        style={{
          borderRight: '1px solid #fff',
          paddingRight: '10px',
          marginRight: '10px',
        }}
      >
        <img
          src={LogoFlexxible}
          alt="logo"
          style={{
            width: '100px',
          }}
        />
        <b
          style={{
            fontSize: '1.3em',
            color: theme.palette?.themePrimary,
          }}
        >
          Demo
        </b>
      </div>
    )
  }

  if (RELEASE_ENVIRONMENT !== Environment.STAGING) {
    return null
  }

  return (
    <div
      className="banner__wrapper"
      style={{
        color: theme.palette?.white,
      }}
    >
      {renderLogo()}
      {releaseDataItems.map(renderReleaseItem)}
    </div>
  )
}
