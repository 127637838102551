import { useTranslation } from 'react-i18next';
import backgroundImage from '../../assets/img/Login.jpg';
import LogoFlexxible from '../../assets/img/logo-negative.svg';
import { LoginFeature } from './feature/v2/Login.feature';
import './Login.page.scss';

export const LoginPage = () => {
  const { t } = useTranslation('login');
  return (
    <main className="login" style={{ backgroundImage: `url(${backgroundImage})` }}>
      <div className="login__wrapper">
        <section className="login__card">
          <img src={LogoFlexxible} width="306px" alt="logo" className="login__card__logo"/>
          <h1 className="login__card__title">{t('LOGIN_TITLE')}</h1>
          <hr className="login__card__divider"/>
          <div className="login__card__auth">
            <LoginFeature />
          </div>
        </section>
        <div className="login__card-shadow"/>
      </div>
      <section className="login__footer">
        <img src={LogoFlexxible} width="174px" className="login__footer__logo" alt="logo"/>
      </section>
    </main>
  )
}
