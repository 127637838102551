import { useContext } from 'react'
import { GlobalContext } from './context/GlobalContext'
import { GlobalContextAuth } from './context/globalContextAuth'

export const useAuth = (): GlobalContextAuth => {
  const { user, logout, setUser } = useContext(GlobalContext).auth
  return {
    user,
    logout,
    setUser,
  }
}
