import { useCallback, useEffect } from 'react'
import { Params, useLocation, useParams, useSearchParams } from 'react-router-dom'

function getRoute(path: string, params: Params<string>) {
  return Object.entries(params).reduce((acc, [key, value]) => {
    if (!value) {
      return acc
    }

    return acc.replace(value, `:${key}`)
  }, path)
}

/** @DEPRECATED */
export const useLocalStorageSearchParams = (persist: boolean = true) => {
  const [searchParams] = useSearchParams()
  const location = useLocation()
  const params = useParams()

  useEffect(() => {
    persist && persistSearchParams()
  }, [searchParams])

  const persistSearchParams = useCallback(() => {
    searchParams.delete('page')

    localStorage.setItem(getRoute(location.pathname, params), `?${searchParams.toString()}`)
  }, [searchParams])

  const getPersistedSearchParams = useCallback(
    (url: string) => {
      const path = getRoute(url, params)

      return localStorage.getItem(path) || ''
    },
    [params]
  )

  return {
    persistSearchParams,
    getPersistedSearchParams,
  }
}
