import { createContext, FC, PropsWithChildren, useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

export enum FeedbackMessageType {
  SUCCESS = 'success',
  ERROR = 'error',
  INFO = 'info',
}

export interface FeedbackMessage {
  content: string
  type: FeedbackMessageType
}

interface FeedbackMessageContextType {
  feedbackMessage: FeedbackMessage | undefined
  setSuccessMessage: (message: string, messageDelayMillis?: number) => void
  setErrorMessage: (message: string, messageDelayMillis?: number) => void
  setInfoMessage: (message: string, messageDelayMillis?: number) => void
  clearFeedbackMessage: () => void
}

const FeedbackMessageContext = createContext<FeedbackMessageContextType | undefined>(undefined)

const MESSAGE_DELAY_MILLIS = 3000

export const FeedbackMessageProvider: FC<PropsWithChildren> = ({ children }) => {
  const [message, setMessage] = useState<FeedbackMessage | undefined>()
  const [timer, setTimer] = useState<ReturnType<typeof setTimeout> | undefined>(undefined)
  const location = useLocation()

  const applyDelay = (messageDelayMillis: number = MESSAGE_DELAY_MILLIS) => {
    if (timer) {
      clearTimeout(timer)
      setTimer(undefined)
    }

    setTimer(
      setTimeout(() => {
        if (messageDelayMillis !== 0) {
          setMessage(undefined)
        }
      }, messageDelayMillis)
    )
  }

  useEffect(() => {
    clearFeedbackMessage()
  }, [location.pathname])

  const setFeedbackMessage = (
    message: FeedbackMessage | undefined,
    messageDelayMillis: number = MESSAGE_DELAY_MILLIS
  ) => {
    setMessage(message)
    if (message) {
      applyDelay(messageDelayMillis)
    }
  }

  const setSuccessMessage = (content: string, messageDelayMillis: number = MESSAGE_DELAY_MILLIS) => {
    setFeedbackMessage({ content, type: FeedbackMessageType.SUCCESS }, messageDelayMillis)
  }

  const setErrorMessage = (content: string, messageDelayMillis: number = MESSAGE_DELAY_MILLIS) => {
    setFeedbackMessage({ content, type: FeedbackMessageType.ERROR }, messageDelayMillis)
  }

  const setInfoMessage = (content: string, messageDelayMillis: number = MESSAGE_DELAY_MILLIS) => {
    setFeedbackMessage({ content, type: FeedbackMessageType.INFO }, messageDelayMillis)
  }

  const clearFeedbackMessage = () => {
    if (timer) {
      clearTimeout(timer)
    }
    setFeedbackMessage(undefined)
  }

  return (
    <FeedbackMessageContext.Provider
      value={{ feedbackMessage: message, setSuccessMessage, setErrorMessage, clearFeedbackMessage, setInfoMessage }}
    >
      {children}
    </FeedbackMessageContext.Provider>
  )
}

export const useFeedbackMessage = () => {
  const context = useContext(FeedbackMessageContext)
  if (context === undefined) {
    throw new Error('useFeedbackMessage must be used within a FeedbackMessageProvider')
  }
  return context
}
