import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { gql } from 'graphql-request'
import graphClient from '../../query-client/graphClient'
import { UserSettings } from '../../query-client/types'

const mySettingsQuery = gql`
  query {
    getMySettings {
      _id
      defaultOrganizationSelected
      preferredLanguage
      preferredRegion
    }
  }
`

const getMySettings = (): Promise<UserSettings> =>
  graphClient.request(mySettingsQuery).then((r) => r.getMySettings)

interface UseMySettingsResult {
  mySettings: UserSettings | undefined
}

export const useMySettings = (): UseMySettingsResult => {
  const { data } = useQuery(['getMySettings'], getMySettings, {
    refetchOnWindowFocus: false,
  })
  return {
    mySettings: data,
  }
}
