import type { RouteObject } from 'react-router-dom'

export const analyzerRoutes: RouteObject[] = [
  {
    path: 'analyzer/installed-apps',
    lazy: () => import(/* webpackPrefetch: true */ 'app/analyzer/pages/installed-apps/InstalledAppsPage'),
  },
  {
    path: 'analyzer/installed-apps/:installedAppId',
    lazy: () => import(/* webpackPrefetch: true */ 'app/analyzer/pages/installed-apps/details/InstalledAppDetailsPage'),
  },
  {
    path: 'analyzer/licenses',
    lazy: () => import(/* webpackPrefetch: true */ 'app/analyzer/pages/licenses/LicensesPage'),
  },
  {
    path: 'analyzer/licenses/:licenseId',
    lazy: () => import(/* webpackPrefetch: true */ 'app/analyzer/pages/licenses/LicenseDetailsPage'),
  },
  {
    path: 'analyzer/installed-apps/:installedAppId/version/:version',
    lazy: () => import(/* webpackPrefetch: true */ 'app/analyzer/pages/installed-apps/version-details/InstalledAppVersionDetailsPage'),
  }
]
