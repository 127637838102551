import { createTheme, PartialTheme } from '@fluentui/react'

export const theme: PartialTheme = createTheme({
  palette: {
    themePrimary: '#ff6500',
    themeLighterAlt: '#fff9f5',
    themeLighter: '#ffe7d6',
    themeLight: '#ffd1b3',
    themeTertiary: '#ffa366',
    themeSecondary: '#ff781f',
    themeDarkAlt: '#e65c00',
    themeDark: '#c24e00',
    themeDarker: '#8f3900',
    neutralLighterAlt: '#faf9f8',
    neutralLighter: '#f3f2f1',
    neutralLight: '#edebe9',
    neutralQuaternaryAlt: '#e1dfdd',
    neutralQuaternary: '#d0d0d0',
    neutralTertiaryAlt: '#c8c6c4',
    neutralTertiary: '#c4c7ca',
    neutralSecondary: '#8c9095',
    neutralSecondaryAlt: '#8c9095',
    neutralPrimaryAlt: '#5a5f64',
    neutralPrimary: '#464a4f',
    neutralDark: '#35383c',
    green: '#008555',
    greenDark: '#037049',
    greenLight: '#339D77',
    black: '#272a2c',
    white: '#ffffff',
  },
  defaultFontStyle: { fontFamily: 'Aeonik' },
  components: {
    Link: {
      styles: {
        root: {
          color: 'var(--black)',
          textDecoration: 'underline',
        },
      },
    },
  },
})
