import React, { type ReactElement } from 'react'
import { default as DoptTour, TourItem, useTourItem } from '@dopt/react-tour'
import { useTranslation } from 'react-i18next'
import { FlexxiblePrimaryButton, FlexxibleSecondaryButton } from '@flexxibleit/flexxible-ui'
import { createTheme } from '@dopt/react-theme'

export interface TourProps {
  id: string
  children: ReactElement
  position?: 'top' | 'right' | 'bottom' | 'left'
  onClickNext?: () => void
  onClickBack?: () => void
}

export const Tour = (props: TourProps) => {
  const { id, children, position = 'top' } = props
  const { t } = useTranslation('user_journey')
  const item = useTourItem(id)

  const customTheme = createTheme({
    colors: {
      primary: 'var(--theme-primary)',
      secondary: 'var(--theme-secondary)',
    },
  })
  const handleOnClickNext = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    e.preventDefault()
    props.onClickNext?.()
    item.next()
  }

  const handleOnClickBack = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    e.preventDefault()
    props.onClickBack?.()
    item.back()
  }

  const getBodyText = (body?: any[] | null): string => {
    if (!body || !body[0] || !body[0].children || !body[0].children[0]) {
      return ''
    }

    return body[0].children[0].text
  }

  const isLastStep = (item: TourItem): boolean => {
    return !!(item.tour && item.index === item.tour.size - 1)
  }

  return (
    <DoptTour.Root theme={customTheme} active={item.active}>
      <DoptTour.Anchor>{children}</DoptTour.Anchor>
      <DoptTour.Popover
        style={{
          zIndex: 3,
          maxWidth: '300px',
          fontSize: '14px',
          fontFamily: 'Aeonik',
        }}
        position={position}
      >
        <DoptTour.Content>
          <DoptTour.Header>
            <DoptTour.Title>{t(`NAVIGATE_MODULE_FLOW.STEPS.${item.title}`)}</DoptTour.Title>
          </DoptTour.Header>
          <p
            style={{
              margin: '0 0 20px',
            }}
          >
            {t(`NAVIGATE_MODULE_FLOW.STEPS.${getBodyText(item.body)}`)}
          </p>
          <DoptTour.Footer>
            {item.index !== 0 && (
              <FlexxibleSecondaryButton onClick={handleOnClickBack}>
                {t('NAVIGATE_MODULE_FLOW.BACK_LABEL')}
              </FlexxibleSecondaryButton>
            )}
            {isLastStep(item) ? (
              <FlexxiblePrimaryButton onClick={handleOnClickNext}>
                {t('NAVIGATE_MODULE_FLOW.FINISH_LABEL')}
              </FlexxiblePrimaryButton>
            ) : (
              <FlexxiblePrimaryButton onClick={handleOnClickNext}>
                {t('NAVIGATE_MODULE_FLOW.NEXT_LABEL')}
              </FlexxiblePrimaryButton>
            )}
          </DoptTour.Footer>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '20px',
            }}
          >
            <DoptTour.Progress count={item.tour?.size || 1} index={item.index || 0} />
          </div>
        </DoptTour.Content>
      </DoptTour.Popover>
    </DoptTour.Root>
  )
}
