import { useQuery } from '@tanstack/react-query'
import { gql } from 'graphql-request'
import graphClient from '../../query-client/graphClient'
import { Business } from '../../query-client/types'

const getMe = gql`
  {
    getMe {
      _id
      name
      email
      role
      business {
        _id
        name
        serviceNowCompanyId
        uniqueCode
        productConfigs {
          _id
          environment
          created_at
          active
          license
          baseline
          serviceNowRelatedEnvironment
          product {
            _id
            name
            active
          }
        }
        modules {
          _id
          type
          name
          tag
          url
          visibility
          business {
            _id
          }
        }
      }
    }
  }
`

function getProductConfig(): Promise<Business> {
  return graphClient.request(getMe).then((r) => r.getMe.business)
}

export const useMyOrganization = () => {
  return useQuery(['getMyOrganization'], () => getProductConfig(), {
    refetchOnWindowFocus: false,
  })
}
