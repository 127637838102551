import type { RouteObject } from 'react-router-dom'

export const wrokspaceGroupsRoutes: RouteObject[] = [
  {
    path: '/workspace-groups',
    lazy: () => import(/* webpackPrefetch: true */ 'app/pages/workspace-groups/workspace-groups.page'),
  },
  {
    path: '/workspace-groups/:workspaceGroupId',
    lazy: () =>
      import(
        /* webpackPrefetch: true */ 'app/pages/organization-details/workspace-groups/workspace-group-detail.page'
      ).then((m) => ({
        Component: m.WorkspaceGroupDetailPageGroups,
      })),
  },
]
