import React, { ReactNode } from 'react'
import './MobileUserContextMenu.scss'
import iconImage from '../../../assets/img/icoFlexx.svg'
import { useTranslation } from 'react-i18next'
import { useAuth } from '../../../globals/useAuth'
import { roleLocalizationKeyDictionary } from '../../../permissions/RoleFeature.enum'
import useMediaQuery from '../../../hooks/utils/useMediaQuery'
import { MOBILE_QUERY, TABLET_QUERY } from '../../../config/media-query'
import { useOrganizationSelection } from 'app/globals/useOrganizationSelection'

interface Props {
  children: ReactNode
  open: boolean
}

export const MobileUserContextMenu = ({ children, open }: Props) => {
  const [isMobile] = useMediaQuery(MOBILE_QUERY)
  const [isTablet] = useMediaQuery(TABLET_QUERY)
  const { t } = useTranslation()
  const { user } = useAuth()
  const { rolesOnBusiness } = useOrganizationSelection()

  if (!user || !open) {
    return null
  }
  return (
    <main className={`header-bar-persona_user_context_menu ${isMobile || isTablet ? 'header-bar-mobile' : ''}`}>
      <div className="header-bar-persona_user_context_menu__user__info">
        <div className="header-bar-persona_user_context_menu__icons">
          <img src={iconImage} alt="flexx icon" />
          <img src={iconImage} alt="flexx icon" />
        </div>
        <div>
          <span className="header-bar-persona_user_context_menu__user__info__name">
            {rolesOnBusiness && t(roleLocalizationKeyDictionary[rolesOnBusiness.portal])}
          </span>
          <span className="header-bar-persona_user_context_menu__user__info__email">{user.email}</span>
        </div>
      </div>
      <div className="header-bar-persona_user_context_menu__top">{children}</div>
    </main>
  )
}
