import { Icon } from '@fluentui/react'
import './AppSwitcher.scss'
import React, { useState } from 'react'
import { default as FlexxWorkspacesAppIcon } from '../../assets/img/flexworkspaces.svg'
import { default as FlexxBeatAppIcon } from '../../assets/img/flexautomation.svg'
import { default as FlexxAnalyzerAppIcon } from '../../assets/img/flexanalyzer.svg'
import { MOBILE_QUERY, TABLET_QUERY, useMediaQuery } from '@flexxibleit/flexxible-ui'
import { useOrganizationSelection } from '../../globals/useOrganizationSelection'
import { ModuleType, ModuleTypes } from '../../pages/organization-details/modules/module-form/types'
import { Tour } from '../../user-journey/getting-started-checklist/Tour'
import { useTourItem } from '@dopt/react-tour'
import { useGettingStartedChecklist } from '../../user-journey/getting-started-checklist/useGettingStartedChecklist'
import { GettingStartedChecklistTasks } from '../../user-journey/getting-started-checklist/GettingStartedChecklistTasksEnum'
import { SelectableOrganization } from 'app/hooks/organization/useGetOrganizationsForSelection'

export interface AvailableApp {
  name: string
  url: string
  icon: string
  tourId?: string
}

export const AppSwitcher = () => {
  const [isMobile] = useMediaQuery(MOBILE_QUERY)
  const [isTablet] = useMediaQuery(TABLET_QUERY)
  const [appSwitcherSidebarOpen, setAppSwitcherSidebarOpen] = useState(false)
  const { selectedOrganization } = useOrganizationSelection()
  const appSwitcherTourStep = useTourItem('navigate-to-different-module.app-switcher-item')
  const analyzerTourStep = useTourItem('navigate-to-different-module.analyzer-item')
  const workspacesTourStep = useTourItem('navigate-to-different-module.workspaces-item')
  const { completeGettingStartedTask } = useGettingStartedChecklist()
  const handleAppSwitcherClick = () => {
    setAppSwitcherSidebarOpen(!appSwitcherSidebarOpen)
  }
  const handleOnClickApp = (e: React.MouseEvent<HTMLDivElement>, url: string) => {
    e.stopPropagation()
    e.preventDefault()
    window.open(url, '_blank')
  }
  const buildAvailableModules = (organization: SelectableOrganization) => {
    const analyzerModule = organization?.modules?.find((module) => module.type === ModuleType.FlexxAnalyzer)
    const workspacesModule = organization?.modules?.find((module) => module.type === ModuleType.FlexxWorkspaces)
    const automateModule = organization?.modules?.find((module) => module.type === ModuleType.FlexxAutomation)
    const monitorModule = organization?.modules?.find((module) => module.type === ModuleType.FlexxMonitoring)
    const analyzerApp: AvailableApp | undefined = analyzerModule
      ? {
          name: ModuleTypes.find((module) => module.key === ModuleType.FlexxAnalyzer)?.text || '',
          url: analyzerModule?.url,
          icon: FlexxAnalyzerAppIcon,
        }
      : undefined

    const workspacesApp: AvailableApp | undefined = workspacesModule
      ? {
          name: ModuleTypes.find((module) => module.key === ModuleType.FlexxWorkspaces)?.text || '',
          url: workspacesModule?.url || '',
          icon: FlexxWorkspacesAppIcon,
        }
      : undefined

    const automateApp: AvailableApp | undefined = automateModule
      ? {
          name: ModuleTypes.find((module) => module.key === ModuleType.FlexxAutomation)?.text || '',
          url: automateModule?.url || '',
          icon: FlexxBeatAppIcon,
        }
      : undefined

    const monitorApp: AvailableApp | undefined = monitorModule
      ? {
          name: ModuleTypes.find((module) => module.key === ModuleType.FlexxMonitoring)?.text || '',
          url: monitorModule?.url || '',
          icon: FlexxAnalyzerAppIcon,
        }
      : undefined

    return {
      analyzerApp,
      workspacesApp,
      automateApp,
      monitorApp,
    }
  }

  const { analyzerApp, workspacesApp, automateApp, monitorApp } = buildAvailableModules(selectedOrganization)

  const renderAppSelector = () => {
    return (
      <div className="app-selector">
        <div className={'app-selector__apps'}>
          {analyzerApp && (
            <Tour id={analyzerTourStep.id} position={'bottom'}>
              <div className="app-selector__apps__app" onClick={(e) => handleOnClickApp(e, analyzerApp.url)}>
                <img className="app-selector__apps__app-icon" src={analyzerApp.icon} alt={analyzerApp.name} />
                <a href={analyzerApp.url} target="_blank">
                  {analyzerApp.name}
                </a>
              </div>
            </Tour>
          )}
          {workspacesApp && (
            <Tour
              id={workspacesTourStep.id}
              position={'bottom'}
              onClickNext={() => {
                completeGettingStartedTask(GettingStartedChecklistTasks.NAVIGATE_DIFFERENT_MODULE_TASK)
              }}
            >
              <div className="app-selector__apps__app" onClick={(e) => handleOnClickApp(e, workspacesApp.url)}>
                <img className="app-selector__apps__app-icon" src={workspacesApp.icon} alt={workspacesApp.name} />
                <a href={workspacesApp.url} target="_blank">
                  {workspacesApp.name}
                </a>
              </div>
            </Tour>
          )}
          {automateApp && (
            <div className="app-selector__apps__app" onClick={(e) => handleOnClickApp(e, automateApp.url)}>
              <img className="app-selector__apps__app-icon" src={automateApp.icon} alt={automateApp.name} />
              <a href={automateApp.url} target="_blank">
                {automateApp.name}
              </a>
            </div>
          )}
          {monitorApp && (
            <div className="app-selector__apps__app" onClick={(e) => handleOnClickApp(e, monitorApp.url)}>
              <img className="app-selector__apps__app-icon" src={monitorApp.icon} alt={monitorApp.name} />
              <a href={monitorApp.url} target="_blank">
                {monitorApp.name}
              </a>
            </div>
          )}
        </div>
      </div>
    )
  }

  return (
    <Tour id={appSwitcherTourStep.id} position={'bottom'} onClickNext={() => setAppSwitcherSidebarOpen(true)}>
      <div
        className={`app-switcher ${isMobile || isTablet ? 'mobile' : ''} ${
          appSwitcherSidebarOpen ? 'app-switcher__open' : ''
        }`}
        onClick={handleAppSwitcherClick}
      >
        <Icon iconName="Waffle" />

        {appSwitcherSidebarOpen && renderAppSelector()}
      </div>
    </Tour>
  )
}
