import { IButtonProps } from "@fluentui/react";
import { ReactComponent as Icon } from "../../assets/img/error_icon.svg"
import StatusLayout from "./status-layout/status-layout";

export interface ErrorStatusProps {
  message: string;
  buttonProps?: IButtonProps;
}
const ErrorStatus = ({ message, buttonProps }: ErrorStatusProps) => {

  return (
    <StatusLayout
      img={ <Icon fill='#BD0000' style={ { width: '113px', height: '113px' } } /> }
      message={ message }
      buttonProps={ buttonProps }
    />
  )
}

export default ErrorStatus
