import { LoadingSpinner } from '@flexxibleit/flexxible-ui'
import { useAuth0 } from '@auth0/auth0-react'
import { useLoginV2 } from '../../../../authentication/v2/useLoginV2'
import React, { useEffect, useState } from 'react'
import { FontIcon, PrimaryButton } from '@fluentui/react'
import { useTranslation } from 'react-i18next'
import { useAuth } from '../../../../globals/useAuth'
import { useAvailableAuthUsers } from '../../../../hooks/me/useAvailableAuthUsers'
import { SearchableDropdown } from '../../../../components/SearchableDropdown/SearchableDropdown.component'

export const LoginFeature = () => {
  const {
    isLoading,
    isAuthenticated,
    error: auth0Error,
    loginWithRedirect,
    getIdTokenClaims,
    logout: auth0Logout,
  } = useAuth0()
  const { mutate: apiLogin, error: apiError } = useLoginV2()
  const { logout: appLogout } = useAuth()
  const { t } = useTranslation('login')
  const [error, setError] = useState(() => auth0Error || apiError)
  const { mutate: getAvailableAuthUsersRequest, data: availableUsersResponse, error: availableUsersError } =
    useAvailableAuthUsers()
  const [auth0Token, setAuth0Token] = useState<string | null>(null)
  const [availableUsers, setAvailableUsers] = useState<any[]>([])

  const setTokenOnAuth0Login = () => {
    getIdTokenClaims().then(async (claims) => {
      if (claims) {
        setAuth0Token(claims.__raw)
      }
    })
  }

  const getAvailableUsersOnAuth0TokenObtained = () => {
    if (auth0Token) {
      getAvailableAuthUsersRequest(auth0Token)
    }
  }

  const apiLoginOnAvailableUsers = () => {
    if (!availableUsersResponse) {
      return
    }

    if (availableUsersResponse.length > 1) {
      setAvailableUsers(availableUsersResponse)
      return
    }

    apiLogin({ token: auth0Token, organizationId: availableUsersResponse[0].business?._id })
  }

  const performLogout = () => {
    appLogout()
    auth0Logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    })
  }

  const renderError = () => {
    let errorMessage = t('LOGIN_ERROR')
    if (error?.response?.errors?.[0]?.message === "User not found") {
      errorMessage = t('LOGIN_ERROR_USER_NOT_FOUND')
    }

    return (
      <>
        <FontIcon
          iconName="AlertSolid"
          style={{ color: '#FFF', fontSize: 30 }}
        />
        <h1 className="login__error">{errorMessage}</h1>
        <PrimaryButton className="login__retry" onClick={performLogout}>
          {t('LOGIN_RETRY')}
        </PrimaryButton>
      </>
    )
  }

  const renderLogin = () => {
    if (error) {
      return renderError()
    }

    if (availableUsers.length > 1) {
      return (
        <>
          <SearchableDropdown
            dropdownProps={{
              label: t('SELECT_ORGANIZATION_DROPDOWN_LABEL'),
              placeholder: t('SELECT_ORGANIZATION_DROPDOWN_PLACEHOLDER'),
              styles: {
                root: {
                  marginBottom: '60px',
                  minWidth: '200px',
                },
                label: {
                  color: 'var(--white)',
                },
              },
              options: availableUsers.map((user) => ({
                key: user._id,
                text: user.business.name,
              })),
              onChange: (e, option) => {
                const selectedUser = availableUsers.find(
                  (user) => user._id === option?.key
                )
                apiLogin({
                  token: auth0Token,
                  organizationId: selectedUser?.business?._id,
                })
              },
            }}
          />
          <PrimaryButton onClick={performLogout}>
            {t('LOGOUT_BUTTON')}
          </PrimaryButton>
        </>
      )
    }

    return <LoadingSpinner />
  }

  const setErrorIfAuth0OrApiError = () => {
    if (auth0Error || apiError || availableUsersError) {
      setError(apiError || auth0Error || availableUsersError)
    }
  }

  const performApiLoginIfAuth0Authenticated = () => {
    if (isLoading) {
      return
    }

    if (error) {
      return
    }

    if (isAuthenticated) {
      setTokenOnAuth0Login()
    }
  }

  const redirectToAuth0LoginIfNotLoggedIn = () => {
    if (isLoading) {
      return
    }

    if (!isAuthenticated) {
      loginWithRedirect()
    }
  }

  useEffect(redirectToAuth0LoginIfNotLoggedIn, [isLoading, isAuthenticated])

  useEffect(setErrorIfAuth0OrApiError, [auth0Error, apiError, availableUsersError])

  useEffect(performApiLoginIfAuth0Authenticated, [
    isAuthenticated,
    isLoading,
    error,
  ])

  useEffect(getAvailableUsersOnAuth0TokenObtained, [auth0Token])

  useEffect(apiLoginOnAvailableUsers, [availableUsersResponse])

  return (
    <div
      style={{
        marginTop: 30,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      {renderLogin()}
    </div>
  )
}
