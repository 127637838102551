import { FlexxiblePrimaryButton } from '@flexxibleit/flexxible-ui'
import { Icon } from '@fluentui/react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import EmptyReports from '../../../app/assets/img/Vector.svg'
import StatusLayout from './status-layout/status-layout'

interface Props {
  message: string
  explanation: string
}

const Status404 = ({ message, explanation }: Props) => {
  const { t } = useTranslation('general')
  const navigate = useNavigate()

  return (
    <StatusLayout
      img={EmptyReports}
      message={message}
      explanation={explanation}
      imgWithContainer={true}
      renderButton={
        <FlexxiblePrimaryButton
          className="vertical-margin-3"
          onClick={() => {
            navigate('/')
          }}
        >
          <Icon
            iconName="SkypeArrow"
            styles={{
              root: {
                marginRight: '0.5rem',
              },
            }}
          />
          <span>{t('BUTTON.GO_HOME')}</span>
        </FlexxiblePrimaryButton>
      }
    />
  )
}

export default Status404
