import { Environment } from './environment.enum'
import { ProductEnvTypes } from './productEnv.enum'

/* eslint-disable no-undef */
export const MICROSOFT_CLIENT_ID = ''
export const BASE_URL = process.env.REACT_APP_BASE_URL
export const GRAPH_BASE_URL = process.env.REACT_APP_GRAPH_BASE_URL || 'https://flexxible-api.redsanitaria.com:3000/api'
export const STORAGE_USER_KEY = 'user'
export const STORAGE_I18N = 'i18nextLng'
export const BASELINE_MIN_VALUE = 0
export const BASELINE_MAX_VALUE = 9999999
export const BASELINE_STEP_VALUE = 1
export const PRODUCT_ENV = process.env.REACT_APP_PRODUCT_ENV === 'FXXONE' ? ProductEnvTypes.FXXONE : null
export const ENV = process.env.REACT_APP_ENV === 'STAGING' ? Environment.STAGING : Environment.PRODUCTION
export const EXTENDED_PATCH_MANAGEMENT_ENABLED =
  process.env.REACT_APP_EXTENDED_PATCH_MANAGEMENT_ENABLED &&
  process.env.REACT_APP_EXTENDED_PATCH_MANAGEMENT_ENABLED.toLowerCase() === 'true'
export const RELEASE_BRANCH = process.env.REACT_APP_RELEASE_BRANCH
export const RELEASE_BRANCH_COMMIT  = process.env.REACT_APP_RELEASE_BRANCH_COMMIT
export const RELEASE_LAST_UPDATED = process.env.REACT_APP_RELEASE_LAST_UPDATED
export const RELEASE_ENVIRONMENT = process.env.REACT_APP_ENV
