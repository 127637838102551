import { GettingStartedChecklistSummary } from './GettingStartedChecklistSummary'
import { Checklist as ChecklistType, useChecklist } from '@dopt/react-checklist'
import { Icon, Label } from '@fluentui/react'
import { useTranslation } from 'react-i18next'
import { useFlow } from '@dopt/react'
import { getTagFromState, refreshFlowIconStyles } from '../shared'
import { GettingStartedChecklistTasks } from './GettingStartedChecklistTasksEnum'
import { GettingStartedChecklistSidebar } from './components/GettingStartedChecklistSidebar'
import { useFeatureRender } from 'app/permissions/useFeatureRender'
import { FEATURE_NAMES } from 'app/permissions/FeatureName.enum'

interface UseGettingStartedChecklistReturn {
  checklist: ChecklistType
  gettingStartedHome: JSX.Element
  gettingStartedSidebar: JSX.Element
  completeGettingStartedTask: (task: GettingStartedChecklistTasks) => void
  gettingStartedSettings: JSX.Element
}

export const useGettingStartedChecklist = (): UseGettingStartedChecklistReturn => {
  const { t } = useTranslation('user_journey')
  const { checkFeature } = useFeatureRender()
  const checklist: ChecklistType = useChecklist('portal-getting-started-checklist.getting-started-checklist')
  const [checklistFlow, intentChecklistFlow] = useFlow('portal-getting-started-checklist')

  const checklistFlowEnabled = checklistFlow.blocks.length > 0

  if (!checklistFlowEnabled || !checkFeature(FEATURE_NAMES.USER_JOURNEY_READ)) {
    return {
      checklist,
      gettingStartedHome: <></>,
      gettingStartedSidebar: <></>,
      completeGettingStartedTask: () => {},
      gettingStartedSettings: <></>,
    }
  }

  const completeGettingStartedTask = (task: GettingStartedChecklistTasks) => {
    const checklistTask = checklist.items.find((item) => item.id === task)
    if (checklistTask && !checklistTask.completed) {
      checklistTask.complete()
    }
  }

  const gettingStartedSettings = (
    <>
      <Label className={'mt-2'}>{t('GETTING_STARTED_CHECKLIST.RESET_LABEL')}</Label>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          marginTop: '8px',
          gap: '8px',
        }}
      >
        {getTagFromState(checklistFlow.state, t)}
        <Icon
          iconName="Refresh"
          onClick={() => {
            intentChecklistFlow.reset()
          }}
          styles={refreshFlowIconStyles}
        />
      </div>
    </>
  )

  return {
    checklist,
    gettingStartedHome: <GettingStartedChecklistSummary />,
    gettingStartedSidebar: <GettingStartedChecklistSidebar />,
    completeGettingStartedTask,
    gettingStartedSettings,
  }
}
