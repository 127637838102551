import { useMutation, useQueryClient } from '@tanstack/react-query'
import graphClient from '../../query-client/graphClient'
import { gql } from 'graphql-request'

interface CreateConnectionRequest {
  userId: string
}

const mutation = gql`
  mutation CreateConnection($input: CreateConnectionGraphqlRequest!) {
    createConnection(input: $input) {
      _id
    }
  }
`

interface CreateConnectionResponse {
  createConnection: {
    _id: string
  }
}

async function createConnection(connection: CreateConnectionRequest) {
  const { createConnection }: CreateConnectionResponse = await graphClient.request(mutation, { input: connection })

  return createConnection
}

export default function () {
  const queryClient = useQueryClient()

  return useMutation(['createConnection'], (connection: CreateConnectionRequest) => createConnection(connection), {
    onSuccess: () => {
      queryClient.invalidateQueries(['connections'])
      queryClient.invalidateQueries(['getConnections'])
    },
  })
}
