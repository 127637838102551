import { useLocalStorageSearchParams } from '../hooks/utils/useLocalStorageSearchParams'
import { useLocation, useNavigate } from 'react-router-dom'
import { useEffect } from 'react'

export const KeepQueryParams = () => {
  const { getPersistedSearchParams } = useLocalStorageSearchParams(false)
  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    if (location.pathname !== '/' && location.search === '') {
      const persistSearchParams = getPersistedSearchParams(location.pathname)
      if (persistSearchParams) {
        navigate(location.pathname + persistSearchParams)
      }
    }
  }, [])

  return <></>
}
