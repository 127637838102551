import { RouteObject } from 'react-router-dom'

export const productsRoutes: RouteObject[] = [
  {
    path: '/products',
    children: [
      { index: true, lazy: () => import(/* webpackPrefetch: true */ 'app/pages/products/product.page') },
      {
        path: ':productId',
        lazy: () => import(/* webpackPrefetch: true */ 'app/pages/report-explorer/report-explorer.page'),
      },
    ],
  },
]
