import { useFeaturit } from 'app/globals/context/FeaturitProvider'
import { ViewAsEndUser } from './types'
import { useViewAsEndUserStorage } from './useViewAsEndUserStorage'
import './styles.scss'
import { Toggle } from '@fluentui/react'
import { viewAsEndUserSwitcherStyles } from './viewAsEndUserSwitcherStyles'
import { FeaturitFlag } from '../../permissions/authorizer/FeatureFlags'

export function ViewAsEndUserSwitcher() {
  const featurit = useFeaturit()
  const { viewAsEndUser } = useViewAsEndUserStorage()

  const isOn = viewAsEndUser === ViewAsEndUser.OFF

  const handleOnChange = (v: boolean) => {
    const event = FeaturitFlag.VIEW_AS_END_USER

    if (v) {
      featurit.emit(event, ViewAsEndUser.OFF)
      return
    }

    featurit.emit(event, ViewAsEndUser.ON)
  }

  return (
    <div className="view-as-end-user">
      <Toggle
        checked={isOn}
        inlineLabel
        label="Preview"
        styles={viewAsEndUserSwitcherStyles(isOn)}
        onChange={(_, v) => {
          handleOnChange(v!)
        }}
      />
    </div>
  )
}
