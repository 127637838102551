import { useLocalStorage } from 'app/hooks/utils/useLocalStorage'

type EnsMessageStatus = 'open' | 'closed'

export const useEnsMessage = () => {
  const key = 'ens-message'
  const localStorageValue = localStorage.getItem(key)
  const initialState: EnsMessageStatus = isValidEnsStatus(localStorageValue) ? localStorageValue : 'open'

  const [ensMessageState, setEndMessageState] = useLocalStorage<EnsMessageStatus>(key, initialState)

  return {
    ensMessageState,
    setEndMessageState,
  }
}

function isValidEnsStatus(v: string | null): v is EnsMessageStatus {
  if (v && (v === 'open' || v === 'closed')) return true
  return false
}
