import { FC, ReactNode } from 'react'
import { FEATURE_NAME } from './FeatureName.enum'
import { useFeatureRender } from './useFeatureRender'

interface Props {
  feature: FEATURE_NAME
  children: ReactNode
}
export const FeatureRender: FC<Props> = ({ feature, children }: Props) => {
  const { hasFeature } = useFeatureRender(feature)

  if (!hasFeature) {
    return <></>
  }

  return <>{children}</>
}
