import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { Business, User } from '../../query-client/types'
import graphClient from '../../query-client/graphClient'
import { gql } from 'graphql-request'

const getMeQuery = gql`
  {
    getMe {
      _id
      name
      created_at
      surname
      email
      role
      business {
        _id
        name
        uniqueCode
      }
      specialPermissions
    }
  }
`

function getMe(): Promise<Business> {
  return graphClient.request(getMeQuery).then((r) => r.getMe)
}

export const useMe = (): UseQueryResult<User> => {
  return useQuery(['getMe'], () => getMe(), {
    refetchOnWindowFocus: false,
  })
}
