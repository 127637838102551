import React, { ReactNode } from 'react'
import { Icon } from '@fluentui/react'
import './ContextMenuButton.scss'

interface SettingsButtonProps {
  name: string
  icon?: string
  variant?: 'text' | 'icon'
  onClick?: () => void
    children?: ReactNode
}

export const ContextMenuButton = (props: SettingsButtonProps) => {

  if (props.icon && props.variant === 'icon') {
    return <Icon iconName={props.icon} />
  }

  return (
    <button id={'context-menu-button'} onClick={props.onClick}>
      <Icon className={'context-menu-icon'} iconName={props.icon} />
        {props.name}
    </button>
  )
}
