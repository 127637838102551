import { useContext } from 'react'
import { GlobalContext } from './context/GlobalContext'
import { GlobalContextOrganizationSelection } from './context/globalContextOrganizationSelection'

export const useOrganizationSelection = (): GlobalContextOrganizationSelection => {
  const { selectedOrganizationId, selectedOrganization, setSelectedOrganizationId, rolesOnBusiness } =
    useContext(GlobalContext).organizationSelection
  return {
    selectedOrganizationId,
    selectedOrganization,
    setSelectedOrganizationId,
    rolesOnBusiness,
  }
}
