import {
  Dropdown as FluentDropdown,
  ICalloutProps,
  IDropdownProps,
  IDropdownStyles,
  IRenderFunction,
} from '@fluentui/react'
import { useFormContext, useFormState } from 'react-hook-form'
import { TFunction } from 'react-i18next'
import Controlled from '../Controlled'
import { TooltipInfo } from 'app/components/Tooltip/TooltipInfo'

export interface DropdownProps {
  name: string
  dropdownProps: IDropdownProps
  translator: TFunction
  required?: boolean
  onChange?: any
  styles?: Partial<IDropdownStyles>
  calloutProps?: ICalloutProps
  tooltip?: string
}

export const defaultDropdownStyles: Partial<IDropdownStyles> = {
  dropdownItemSelected: {
    '&:before': {
      content: '""',
      position: 'absolute',
      left: 0,
      top: 0,
      bottom: 0,
      width: '4px',
      background: 'rgb(255, 101, 0)',
    },
  },
}

const Dropdown = ({
  name,
  dropdownProps,
  translator,
  required = false,
  onChange,
  calloutProps,
  tooltip,
}: DropdownProps) => {
  const { control } = useFormContext()
  const { errors } = useFormState({ control })
  const errorMessageKey = errors[name]?.message

  const onWrapDefaultLabelRenderer = (props?: IDropdownProps, defaultRender?: IRenderFunction<IDropdownProps>) => {
    let onRenderLabel = null
    if (dropdownProps.onRenderLabel) {
      onRenderLabel = dropdownProps.onRenderLabel(props)
    }

    return defaultRender ? (
      <>
        <span className={required ? 'required' : ''}>
          {defaultRender(props)} {onRenderLabel}
        </span>
        {tooltip && <TooltipInfo text={tooltip} />}
      </>
    ) : null
  }

  return (
    <>
      <Controlled
        name={name}
        render={({ field }) => (
          <FluentDropdown
            selectedKey={field.value}
            {...field}
            {...dropdownProps}
            styles={dropdownProps.styles ?? defaultDropdownStyles}
            onRenderLabel={onWrapDefaultLabelRenderer}
            calloutProps={{ directionalHintFixed: true, ...calloutProps }}
            onChange={(e: any, o: any) => {
              onChange ? onChange(o) : null
              field.onChange(o.key)
            }}
            errorMessage={
              !!errors[name] ? translator(`ERRORS.${name}_${(errorMessageKey as string).toLowerCase()}`) : ''
            }
          />
        )}
      />
    </>
  )
}

export default Dropdown
