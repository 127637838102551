import { Navigate, useLocation } from 'react-router-dom'
import { useOrganizationSelection } from 'app/globals/useOrganizationSelection'
import { LoadingSpinner } from '@flexxibleit/flexxible-ui'

export const NavigateToMainPage = () => {
  const { rolesOnBusiness, selectedOrganizationId } = useOrganizationSelection()
  const location = useLocation()

  if (!selectedOrganizationId) {
    return <LoadingSpinner />
  }

  switch (rolesOnBusiness?.portal) {
    case 'USER':
    case 'ORGANIZATION_ADMIN':
    case 'ADMIN':
    default:
      return <Navigate to={`/organization/${selectedOrganizationId}/home`} state={{ from: location }} replace />
  }
}
