import { Dropdown } from '@fluentui/react/lib/Dropdown'
import * as React from 'react'
import { useEffect, useState } from 'react'
import {
  IDropdownOption,
  IDropdownProps,
  ISearchBoxProps,
  SearchBox,
} from '@fluentui/react'

interface Props {
  dropdownProps: IDropdownProps
  searchBoxProps?: ISearchBoxProps
}

export const SearchableDropdown = (props: Props) => {
  const [dropdownOptionsState, setDropdownOptionsState] = useState<
    IDropdownOption[]
  >([...props.dropdownProps.options])

  useEffect(() => {
    setDropdownOptionsState(props.dropdownProps.options)
  }, [props.dropdownProps.options])

  const handleOnSearchBoxChange = (
    e: React.ChangeEvent<HTMLInputElement> | undefined,
    value: string | undefined,
    options: IDropdownOption[],
    selectedKey: string | number | string[] | number[] | null | undefined
  ) => {
    const filteredOptions = options.filter((option: IDropdownOption) => {
      return (
        option.text?.toLowerCase().indexOf(value!.toLowerCase()) !== -1 ||
        option.key === selectedKey
      )
    })
    setDropdownOptionsState(filteredOptions)
  }

  const renderList = (dropdownProps: any, defaultRender: any) => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div
          style={{
            margin: '8px',
          }}
        >
          <SearchBox
            {...props.searchBoxProps}
            onChange={(event, value) =>
              handleOnSearchBoxChange(
                event,
                value,
                props.dropdownProps.options,
                props.dropdownProps.selectedKey
              )
            }
          />
        </div>
        {defaultRender!(dropdownProps)}
      </div>
    )
  }

  const handleOnDropdownClick = () => {
    setDropdownOptionsState([...props.dropdownProps.options])
  }

  return (
    <Dropdown
      {...props.dropdownProps}
      options={dropdownOptionsState}
      onRenderList={renderList}
      onClick={handleOnDropdownClick}
    />
  )
}
