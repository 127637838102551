import { Icon, IIconStyleProps, IIconStyles, IStyleFunctionOrObject } from '@fluentui/react'
import { TFunction } from "i18next";

export const refreshFlowIconStyles: IStyleFunctionOrObject<IIconStyleProps, IIconStyles> = {
  root: {
    cursor: 'pointer',
    marginRight: '8px',
    fontSize: '16px',
    selectors: {
      ':hover': {
        color: 'var(--theme-primary)',
      },
    },
  },
}

export const getTagFromState = (state: { stopped: boolean; started: boolean; finished: boolean }, t: TFunction) => {
  let tagText
  let tagColor
  let fontColor
  let icon

  switch (true) {
    case state.started && !state.finished && !state.stopped:
      tagText = t('INITIAL_SETUP_FLOW.STARTED')
      tagColor = '#fff4ce'
      fontColor = '#6e5703'
      icon = 'Play'
      break
    case state.finished && !state.stopped:
      tagText = t('INITIAL_SETUP_FLOW.FINISHED')
      tagColor = '#ceffd5'
      fontColor = '#116e03'
      icon = 'CheckMark'
      break
    default:
      tagText = t('INITIAL_SETUP_FLOW.STARTED')
      tagColor = '#fff4ce'
      fontColor = '#6e5703'
      icon = 'Play'
      break
  }

  return (
    <span
      style={{
        display: 'flex',
        alignItems: 'center',
        padding: '4px',
        borderRadius: '4px',
        backgroundColor: tagColor,
        fontSize: '14px',
        fontWeight: 600,
        color: fontColor,
      }}
    >
      <Icon
        iconName={icon}
        styles={{
          root: {
            marginRight: '4px',
          },
        }}
      />
      {tagText}
    </span>
  )
}
