import { DoptProvider as DoptProviderReact } from '@dopt/react'
import { useIdentifyUser } from '@dopt/react-users'
import { FEATURE_NAMES } from 'app/permissions/FeatureName.enum'
import { useFeatureRender } from 'app/permissions/useFeatureRender'
import { PropsWithChildren } from 'react'
import { useGlobalConfig } from './GlobalConfigContext'
import { globalContextAuth } from './globalContextAuth'

const DroptProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const auth = globalContextAuth()
  const globalConfig = useGlobalConfig()

  const doptUser = useIdentifyUser({
    identifier: auth.user?.email!,
    properties: {
      email: auth.user?.email,
      role: undefined,
    },
  })

  return (
    <DoptProviderReact
      userId={doptUser}
      apiKey={globalConfig?.doptBlocksApiKey ?? ''}
      flowVersions={{
        'portal-initial-setup-wizard': 'latest',
        'portal-getting-started-checklist': 'latest',
        'navigate-to-different-module': 'latest',
      }}
    >
      {children}
    </DoptProviderReact>
  )
}

const DroptProviderAnonymous: React.FC<PropsWithChildren> = ({ children }) => {
  const globalConfig = useGlobalConfig()

  return (
    <DoptProviderReact
      userId={undefined}
      apiKey={globalConfig?.doptBlocksApiKey ?? ''}
      flowVersions={{
        'portal-initial-setup-wizard': 'latest',
        'portal-getting-started-checklist': 'latest',
        'navigate-to-different-module': 'latest',
      }}
    >
      {children}
    </DoptProviderReact>
  )
}

const DoptProviderWrapper: React.FC<PropsWithChildren> = ({ children }) => {
  const { checkFeature } = useFeatureRender()

  if (!checkFeature(FEATURE_NAMES.USER_JOURNEY_READ)) {
    return <DroptProviderAnonymous>{children}</DroptProviderAnonymous>
  }

  return <DroptProvider>{children}</DroptProvider>
}

export default DoptProviderWrapper
