import { useMutation } from '@tanstack/react-query'
import graphClient from '../../query-client/graphClient'
import { gql } from 'graphql-request'
import { User } from '../../query-client/types'

export const getAvailableAuthUsers = async (token: string): Promise<any[]> => {
  const getUsersFromToken = gql`
    mutation {
      getUsersFromToken(token: "${token}") {
        _id
        email
        business {
          _id
          name
        }
      }
    }
  `

  return graphClient
    .request(getUsersFromToken)
    .then((response) => response.getUsersFromToken)
    .then((authUsers) => authUsers.sort(
      (userA: User, userB: User) => {
        const nameA = userA.business.name.toLowerCase()
        const nameB = userB.business.name.toLowerCase()
        if (nameA > nameB) return 1
        if (nameB > nameA) return -1
        return 0
      }
    ))
}

export const useAvailableAuthUsers = () => {
  return useMutation(['availableAuthUsers'], (token: string) =>
    getAvailableAuthUsers(token)
  )
}
