import './GettingStartedChecklist.scss'
import { CircularProgressbar } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'
import { ReactNode, useState } from 'react'
import useMediaQuery from '../../../hooks/utils/useMediaQuery'
import { MOBILE_QUERY, TABLET_QUERY } from '../../../config/media-query'
import { useGettingStartedChecklist } from '../useGettingStartedChecklist'
import { useTranslation } from 'react-i18next'
import { GettingStartedChecklistSummary } from '../GettingStartedChecklistSummary'
import { ChecklistItem } from '@dopt/react-checklist'

export const GettingStartedChecklistSidebar = () => {
  const { t } = useTranslation('user_journey')
  const [isMobile] = useMediaQuery(MOBILE_QUERY)
  const [isTablet] = useMediaQuery(TABLET_QUERY)
  const [checkListSidebarOpen, setCheckListSidebarOpen] = useState(false)
  const { checklist } = useGettingStartedChecklist()

  const handleOnStartTask = (_: ChecklistItem) => {
    setCheckListSidebarOpen(false)
  }

  const renderSidebarButton = (): ReactNode => {
    return (
      <div
        className={'getting-started-checklist-button'}
        onClick={() => {
          setCheckListSidebarOpen(!checkListSidebarOpen)
        }}
      >
        <CircularProgressbar
          value={checklist.count('done')}
          maxValue={checklist.size}
          styles={{
            root: {
              width: 30,
              height: 30,
            },
            path: {
              stroke: 'var(--theme-primary)',
            },
            trail: {},
          }}
        />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginLeft: 10,
            gap: 0,
          }}
        >
          <h4
            style={{
              margin: 0,
            }}
          >
            {t('GETTING_STARTED_CHECKLIST.GETTING_STARTED_CHECKLIST_TITLE')}
          </h4>
          <p
            style={{
              margin: 0,
              fontSize: 12,
              color: 'var(--color-text-secondary)',
            }}
          >
            {checklist.count('not-done')} {t(`GETTING_STARTED_CHECKLIST.REMAINING_TASKS`)}
          </p>
        </div>
      </div>
    )
  }
  const renderSidebarMobileButton = () => {
    return (
      <>
        <div
          style={{
            position: 'fixed',
            bottom: 20,
            right: 10,
            backgroundColor: 'var(--neutral-primary)',
            width: 150,
            padding: '2px 10px 2px 10px',
            height: 50,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: 'white',
            zIndex: 3,
            boxShadow: '0px 0px 10px 0px var(--neutral-primary)',
            borderRadius: '30px',
            gap: 10,
            cursor: 'pointer',
          }}
          onClick={() => {
            setCheckListSidebarOpen(!checkListSidebarOpen)
          }}
        >
          <div
            style={{
              position: 'relative',
              backgroundColor: 'var(--neutral-primary)',
              width: 50,
              height: 50,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              color: 'white',
              zIndex: 3,
              borderRadius: '50%',
            }}
            onClick={() => {
              setCheckListSidebarOpen(!checkListSidebarOpen)
            }}
          >
            <CircularProgressbar
              value={checklist.count('done')}
              maxValue={checklist.size}
              styles={{
                root: {
                  width: 40,
                  height: 40,
                  position: 'fixed',
                  zIndex: 2,
                },
                path: {
                  stroke: 'var(--theme-primary)',
                },
                trail: {},
              }}
            />
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: 0,
              width: '100%',
            }}
          >
            <h4
              style={{
                margin: 0,
              }}
            >
              {t('GETTING_STARTED_CHECKLIST.GETTING_STARTED_CHECKLIST_TITLE')}
            </h4>
            <p
              style={{
                margin: 0,
                fontSize: 12,
                color: 'var(--color-text-secondary)',
              }}
            >
              {checklist.count('not-done')} {t(`GETTING_STARTED_CHECKLIST.REMAINING_TASKS`)}
            </p>
          </div>
        </div>
      </>
    )
  }

  if (!checklist || !checklist.items || checklist.completed) {
    return null
  }

  if (isMobile || isTablet) {
    return (
      <>
        {checkListSidebarOpen && (
          <div className={'getting-started-checklist-sidebar mobile'}>
            {<GettingStartedChecklistSummary onStartTask={handleOnStartTask} />}
          </div>
        )}
        {renderSidebarMobileButton()}
      </>
    )
  }

  return (
    <>
      {checkListSidebarOpen && (
        <>
          <div className={'getting-started-checklist-sidebar'}>
            {<GettingStartedChecklistSummary onStartTask={handleOnStartTask} />}
          </div>
          <div
            style={{ width: '100vw', height: '100vh', position: 'absolute', zIndex: '1' }}
            onClick={() => setCheckListSidebarOpen(false)}
          />
        </>
      )}
      {renderSidebarButton()}
    </>
  )
}
