import Checklist, { ChecklistItem } from '@dopt/react-checklist'
import { useTranslation } from 'react-i18next'
import { createTheme } from '@dopt/react-theme'
import { Card, FlexxiblePrimaryButton, FlexxibleSecondaryButton, OutlinedButton } from '@flexxibleit/flexxible-ui'
import { Icon } from '@fluentui/react'
import { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useOrganizationSelection } from '../../globals/useOrganizationSelection'
import { useGettingStartedChecklist } from './useGettingStartedChecklist'
import useOrganizationById from '../../hooks/organization/useOrganizationById'
import { PRODUCT_ENV } from '../../config/Consts'
import { ProductEnvTypes } from '../../config/productEnv.enum'
import { Module } from '../../query-client/types'
import { ModuleType } from '../../pages/organization-details/modules/module-form/types'
import { useGetEnabledMicroservices } from '../../hooks/microservices/useGetEnabledMicroservices'
import { GettingStartedChecklistTasks } from './GettingStartedChecklistTasksEnum'
import { useFlow } from '@dopt/react'

interface GettingStartedChecklistSummaryProps {
  onStartTask?: (task: ChecklistItem) => void
}

export const GettingStartedChecklistSummary = (props: GettingStartedChecklistSummaryProps) => {
  const { selectedOrganizationId } = useOrganizationSelection()
  const { data: selectedOrganization } = useOrganizationById(selectedOrganizationId!)
  const { data: microservices } = useGetEnabledMicroservices(selectedOrganizationId)
  const [expandedItemIndex, setExpandedItemIndex] = useState<number | null>(null)
  const navigate = useNavigate()
  const { t } = useTranslation('user_journey')
  const { checklist } = useGettingStartedChecklist()
  const customTheme = createTheme({
    colors: {
      primary: 'var(--theme-primary)',
      secondary: 'var(--theme-secondary)',
    },
  })
  const toggleItemExpansion = (index: number) => {
    setExpandedItemIndex((currentExpandedItemIndex) => (currentExpandedItemIndex === index ? null : index))
  }
  const getBodyText = (body?: any[] | null): string => {
    if (!body || !body[0] || !body[0].children || !body[0].children[0]) {
      return ''
    }

    return body[0].children[0].text
  }
  const [navDifferentModuleFlow, navDifferentModuleFlowIntent] = useFlow('navigate-to-different-module')

  const validateInitialChecklistStatus = () => {
    const checklistTasks: ChecklistItem[] = []

    for (const task of checklist?.items) {
      switch (task.id) {
        case GettingStartedChecklistTasks.CREATE_REPORTING_GROUP:
          if (selectedOrganization && selectedOrganization.reportingGroups.length > 0) {
            task.complete()
          }
          checklistTasks.push(task)
          break
        case GettingStartedChecklistTasks.DOWNLOAD_AGENT:
          const isFxxOne = PRODUCT_ENV === ProductEnvTypes.FXXONE
          if (!isFxxOne) {
            task.complete()
            break
          }
          checklistTasks.push(task)
          break
        case GettingStartedChecklistTasks.CONSUMPTION:
          checklistTasks.push(task)
          break
        case GettingStartedChecklistTasks.NAVIGATE_DIFFERENT_MODULE_TASK:
          const analyzerModule: Module | undefined = selectedOrganization?.modules?.find(
            (module) => module.type === ModuleType.FlexxAnalyzer
          )
          const workspacesModule: Module | undefined = selectedOrganization?.modules?.find(
            (module) => module.type === ModuleType.FlexxWorkspaces
          )

          if (analyzerModule && workspacesModule) {
            checklistTasks.push(task)
            break
          }
          task.complete()
          break
        case GettingStartedChecklistTasks.INVITE_PEOPLE:
          if (selectedOrganization && selectedOrganization.users.length > 0) {
            task.complete()
          }
          checklistTasks.push(task)
          break
        case GettingStartedChecklistTasks.ENABLE_MICROSERVICE:
          if (microservices && microservices.length > 0) {
            task.complete()
          }
          checklistTasks.push(task)
          break
        case GettingStartedChecklistTasks.CREATE_TENANT:
          if (
            selectedOrganization &&
            selectedOrganization.elegibleProducts &&
            selectedOrganization.elegibleProducts.length > 0
          ) {
            if (selectedOrganization.tenants.length > 0) {
              task.complete()
            }

            checklistTasks.push(task)
          }
          task.complete()
          break
        case GettingStartedChecklistTasks.ASSIGN_POLICY:
          if (
            selectedOrganization &&
            selectedOrganization.elegibleProducts &&
            selectedOrganization.elegibleProducts.length > 0
          ) {
            if (selectedOrganization.policies.length > 0) {
              task.complete()
            }

            checklistTasks.push(task)
          }
          task.complete()
          break
        default:
          break
      }
    }

    return checklistTasks
  }

  const checklistTasks: ChecklistItem[] = useMemo(() => validateInitialChecklistStatus(), [checklist])

  if (!checklist || !checklist.items || checklist.completed) {
    return null
  }

  const handleStartTask = (item: ChecklistItem, itemIndex: number) => {
    switch (item.id) {
      case GettingStartedChecklistTasks.CREATE_REPORTING_GROUP:
        navigate(`/organization/${selectedOrganizationId}/reporting-groups`)
        break
      case GettingStartedChecklistTasks.DOWNLOAD_AGENT:
        if (!checklist.items[itemIndex - 1].completed) {
          window.alert(t('GETTING_STARTED_CHECKLIST.TASKS.DOWNLOAD_AGENT_ALERT'))
          return
        }
        navigate(`/organization/${selectedOrganizationId}/reporting-groups`)
        break
      case GettingStartedChecklistTasks.CONSUMPTION:
        navigate(`/organization/${selectedOrganizationId}/products`)
        break
      case GettingStartedChecklistTasks.NAVIGATE_DIFFERENT_MODULE_TASK:
        navDifferentModuleFlowIntent.reset({ force: true })
        break
      case GettingStartedChecklistTasks.INVITE_PEOPLE:
        navigate(`/organization/${selectedOrganizationId}/users`)
        break
      case GettingStartedChecklistTasks.ENABLE_MICROSERVICE:
        navigate('/microservices-marketplace')
        break
      case GettingStartedChecklistTasks.CREATE_TENANT:
        navigate(`/organization/${selectedOrganizationId}/tenants`)
        break
      case GettingStartedChecklistTasks.ASSIGN_POLICY:
        navigate(`/organization/${selectedOrganizationId}/policies`)
        break
      default:
        break
    }
    toggleItemExpansion(itemIndex)
    props.onStartTask && props.onStartTask(item)
  }
  const handleSkipTask = (item: ChecklistItem, itemIndex: number) => {
    item.skip()
    toggleItemExpansion(itemIndex)
  }

  return (
    <Checklist.Root
      theme={customTheme}
      id={'getting-started-checklist-root'}
      style={{
        width: '100%',
        margin: '0 auto',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
        border: 'none',
        padding: '0',
        backgroundColor: 'transparent',
        borderRadius: '10px',
        font: 'var(--theme-font)',
        background: 'transparent',
      }}
    >
      <Card
        cardProps={{
          style: {
            background: 'transparent',
          },
        }}
      >
        <Checklist.Header>
          <Checklist.Title>{t(`GETTING_STARTED_CHECKLIST.${checklist.title}`)}</Checklist.Title>
        </Checklist.Header>
        <p>{t(`GETTING_STARTED_CHECKLIST.${getBodyText(checklist.body)}`)}</p>
        <Checklist.Progress
          value={checklistTasks.filter((task) => task.completed).length}
          max={checklistTasks.length}
        />
        <Checklist.Items>
          {checklistTasks.map((item, i) => (
            <Checklist.Item
              index={i}
              key={i}
              style={{
                display: 'flex',
                flexDirection: 'column',
                height: 'auto',
                padding: '0px',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  width: '100%',
                  gap: '10px',
                  height: 'auto',
                  cursor: 'pointer',
                }}
                onClick={() => toggleItemExpansion(i)}
              >
                <Checklist.ItemIcon>
                  {item.completed ? (
                    <Checklist.IconCompleted />
                  ) : item.skipped ? (
                    <Checklist.IconSkipped />
                  ) : (
                    <Checklist.IconActive />
                  )}
                </Checklist.ItemIcon>
                <div
                  style={{
                    marginBottom: '4px',
                    justifySelf: 'start',
                    color: item.completed || item.skipped ? 'var(--neutral-tertiary-alt)' : 'var(--color-text)',
                    textDecoration: item.completed || item.skipped ? 'line-through' : 'none',
                  }}
                >
                  <span
                    style={{
                      fontSize: '12px',
                      color:
                        item.completed || item.skipped ? 'var(--neutral-tertiary-alt)' : 'var(--neutral-secondary)',
                    }}
                  ></span>
                  {t(`GETTING_STARTED_CHECKLIST.TASKS.${item.title}`)}
                </div>
                <span
                  style={{
                    fontSize: '12px',
                    color: item.completed || item.skipped ? 'var(--neutral-tertiary-alt)' : 'var(--neutral-secondary)',
                  }}
                >
                  {item.completed
                    ? ` (${t(`GETTING_STARTED_CHECKLIST.COMPLETED_LABEL`)})`
                    : item.skipped
                    ? ` (${t(`GETTING_STARTED_CHECKLIST.SKIPPED_LABEL`)})`
                    : ''}
                </span>

                <Icon
                  iconName={expandedItemIndex === i ? 'ChevronUp' : 'ChevronDown'}
                  style={{
                    cursor: 'pointer',
                    color: 'var(--theme-primary)',
                    backgroundColor: 'var(--theme-primary-light)',
                    marginRight: '10px',
                    marginLeft: 'auto',
                  }}
                  onClick={() => toggleItemExpansion(i)}
                />
              </div>
              {expandedItemIndex === i && (
                <Checklist.ItemContent>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                      padding: '5px 0px 15px 0',
                      gap: '10px',
                    }}
                  >
                    <p
                      style={{
                        color: item.done ? 'var(--neutral-tertiary-alt)' : 'var(--color-text)',
                        margin: '10px 0',
                      }}
                    >
                      {t(`GETTING_STARTED_CHECKLIST.TASKS.${getBodyText(item.body)}`)}
                    </p>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        padding: '5px 0px 15px 0',
                        gap: '10px',
                      }}
                    >
                      {!item.done && !item.field<boolean>('required') && (
                        <FlexxibleSecondaryButton
                          style={{
                            width: 'fit-content',
                          }}
                          onClick={() => handleSkipTask(item, i)}
                        >
                          {t(`GETTING_STARTED_CHECKLIST.COMPLETE_BUTTON`)}
                        </FlexxibleSecondaryButton>
                      )}
                      <OutlinedButton
                        style={{
                          width: 'fit-content',
                        }}
                        onClick={() => {
                          window.open(item.field<string>('docs_link') || '', '_blank', 'noopener,noreferrer')
                        }}
                      >
                        {t('GETTING_STARTED_CHECKLIST.DOCS_LINK')}
                      </OutlinedButton>
                      <FlexxiblePrimaryButton
                        onClick={() => handleStartTask(item, i)}
                        style={{
                          width: 'fit-content',
                        }}
                      >
                        {t(`GETTING_STARTED_CHECKLIST.START_TASK_BUTTON`)}
                      </FlexxiblePrimaryButton>
                    </div>
                  </div>
                </Checklist.ItemContent>
              )}
            </Checklist.Item>
          ))}
        </Checklist.Items>
      </Card>
    </Checklist.Root>
  )
}
