import { IPersonaProps, Persona, PersonaSize } from '@fluentui/react/lib/Persona'
import { useAuth } from '../../globals/useAuth'
import { useColorfulLanguage } from '../../hooks/utils/useColorfulLanguage'

function LoadPersona(props: { imageUrl: string, imageInitials: string, initialsColor: string, size?: number }) {
  const sharedPersonaProps: IPersonaProps = {
    imageUrl: props.imageUrl,
    imageInitials: props.imageInitials,
    initialsColor: props.initialsColor,
    size: props.size || PersonaSize.size24,
    hidePersonaDetails: true,
  }

  return <Persona {...sharedPersonaProps} />
}

export const PersonaComponent = (props: {size: PersonaSize}) => {
  const {user} = useAuth()
  const initials = user!.email.charAt(0).toUpperCase()
  const initialsColor = useColorfulLanguage(user!.email)

  return (
    <LoadPersona imageUrl='' imageInitials={initials} initialsColor={initialsColor} size={props.size}/>
  )
}