import { TFunction } from 'react-i18next'

export enum BusinessType {
  END_CUSTOMER = 'END CUSTOMER',
  PARTNER = 'PARTNER',
  WHOLESALER = 'WHOLESALER',
}

export const label = (t: TFunction): { [key in BusinessType]: string } => ({
  [BusinessType.END_CUSTOMER]: t('organizations:TYPE.END_CUSTOMER'),
  [BusinessType.PARTNER]: t('organizations:TYPE.PARTNER'),
  [BusinessType.WHOLESALER]: t('organizations:TYPE.WHOLESALER'),
})

export enum BusinessAnalyzer {
    NEW = 'new',
    EXISTING = 'existing'
}