import { UseQueryResult, useQuery } from '@tanstack/react-query'
import graphClient from 'app/query-client/graphClient'
import { gql } from 'graphql-request'

export interface FrontConfig {
  gmApiKey: string
  auth0Domain: string
  auth0ClientId: string
  sentryDns: string
  doptBlocksApiKey: string
  doptUsersApiKey: string
  featuritTenantName: string
  featuritFrontendApiKey: string
}

const query = gql`
  {
    getFrontConfig {
      gmApiKey
      auth0Domain
      auth0ClientId
      sentryDns
      doptBlocksApiKey
      doptUsersApiKey
      featuritTenantName
      featuritFrontendApiKey
    }
  }
`

export const useFrontConfig = (): UseQueryResult<FrontConfig> => {
  return useQuery(
    ['getFrontConfig'],
    () => {
      return graphClient.request(query).then((r) => r.getFrontConfig)
    },
    { refetchOnWindowFocus: false, enabled: true }
  )
}
